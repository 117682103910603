import { Text, Image, Button, Box, Card, ChakraProvider, Container, Heading, Stack, Center, Divider, Grid, GridItem, Stepper, Step, StepIcon, StepIndicator, StepSeparator, StepStatus, StepTitle, useSteps, Checkbox, Slider, SliderFilledTrack, SliderThumb, SliderTrack, NumberInput, NumberInputField, InputGroup, InputRightElement, Spinner, Tooltip, Skeleton, InputLeftElement, HStack, SimpleGrid, Modal, ModalOverlay, ModalBody, ModalContent, AbsoluteCenter, Tab, TabList, TabPanel, TabPanels, Tabs, ModalCloseButton } from "@chakra-ui/react";
import axios from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { baseApplication, baseApplicationFromPrepopulation, formatCurrency } from "../App";
import progressone from '../progressone.svg'
import progresstwo from '../progresstwo.svg'
import progressthree from '../progressthree.svg'
import progressfour from '../progressfour.svg'
import liberiscombinedlogo from '../liberiscombinedlogo.svg'
import storecircle from '../storecircle.svg'
import personcircle from '../personcircle.svg'
import approvalcard from '../approvalcard.svg'
import edit from '../edit.svg'
import dollar from '../dollar.svg'
import stepone from '../stepone.svg'
import steptwo from '../steptwo.svg'
import footer from '../footer.svg'
import { CheckCircleIcon, ChevronUpIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { debounce } from 'lodash';
import { useConfig } from "../hooks/useConfig";

enum Page {
    Review,
    BusinessInfo,
    Offers,
    Contract,
    Thankyou
}

interface Address {
    line1: string;
    line2: string;
    town_city: string;
    postcode: string;
    state: string;
    country: string;
}

interface TradingAddress extends Address {
    trading_name: string;
}

interface RegisteredAddress extends Address { }

interface Company {
    business_type: string;
    industry: string;
    trading_addresses: TradingAddress[];
    currency: string;
    registered_address: RegisteredAddress;
    legal_name: string;
    business_start_date: string;
    partner_start_date: string;
    registration_number: string;
}

interface Residence {
    address: Address;
    residential_status: string;
}

interface Applicant {
    primary: boolean;
    first_name: string;
    last_name: string;
    email_address: string;
    telephone_number: string;
    date_of_birth: string;
    ownership_percentage: number;
    identification_number: string;
    residences: Residence;
}

interface Marketing {
    campaign: string;
    content: string;
    source: string;
    medium: string;
    term: string;
}

interface BankDetails {
    bank_name: string;
    account_number: string;
    account_type: string;
    routing_number: string;
    sort_code: string;
    swift_bic: string;
}

interface Application {
    currency: string;
    merchant_id: string;
}

export interface PrepopulationData {
    application: Application;
    company: Company;
    applicants: Applicant[];
    marketing: Marketing;
    bank_details: BankDetails;
}

const ExampleJourneyPrepopulation: FC = () => {

    const config = useConfig();
    const [page, setPage] = useState<Page>(Page.Review);
    const [selectedOffer, setSelectedOffer] = useState<Offer>();
    const [currency, setCurrency] = useState<string>('GBP');
    const [prepopulationData, setPrepopulationData] = useState<PrepopulationData>();

    useEffect(() => {
        const journeyConfig = localStorage.getItem('journeyconfig');

        console.log('in')

        const location = window.location.toString().replace('#', '/');
        const currencyParam = new URL(location).searchParams.get('currency');
        const merchant_id = new URL(location).searchParams.get('external_id');

        const getPrepopData = async () => {
            const response = await axios.get(`${config?.api.base}/prepopulation?merchant_id=${merchant_id}`)

            const prepopData = response?.data as PrepopulationData;

            if (prepopData) {
                setPrepopulationData(prepopData);
            }
        }

        if (currencyParam && currencyParam !== 'undefined') {
            setCurrency(currencyParam);
        }

        if (!prepopulationData) { getPrepopData() }

    }, [currency])


    return <ChakraProvider>
        {page === Page.Review && (<ReviewLatest setPage={setPage} prepopData={prepopulationData}></ReviewLatest>)}
        {page === Page.Offers && (<OffersLatest setOfferId={setSelectedOffer} setPage={setPage} currency={currency} prepopData={prepopulationData} />)}
        {page === Page.Contract && (<ContractsLatest offer={selectedOffer} setPage={setPage} />)}
        {page === Page.Thankyou && (<ThankyouLatest offer={selectedOffer} />)}
    </ChakraProvider >
}


interface Offer {
    offer_id: string,
    offer_status: string,
    description: string,
    split_percentage: number,
    term_length: number,
    repayment_amount: number,
    funded_amount: number,
    factor_rate: number,
    currency: string,
}


const OffersLatest = (props: { setPage: any, setOfferId: any, currency: string, prepopData?: PrepopulationData }) => {

    interface Offers {
        products: [
            {
                product_id: string,
                name: string,
                description: string,
                decision: string,
                repayment_mechanism: string,
                limits: {
                    minimum: number,
                    maximum: number
                },
                offers: Offer[]
            }
        ],
        expiresAt: Date
    }

    const [offers, setOffers] = useState<Offers>()
    const [amountRequested, setAmountRequested] = useState<number>(0)
    const [selectedOffer, setSelectedOffer] = useState<Offer>()
    const [loading, setLoading] = useState<boolean>(true);
    const [sliderValue, setSliderValue] = useState(0)
    const [inputValue, setInputValue] = useState(0)
    const [showTooltip, setShowTooltip] = useState(false)
    const [outOfBounds, setoutOfBounds] = useState(false)
    const [customSplit, setCustomSplit] = useState(false)
    const config = useConfig()
    const primary = '#0E0B23';

    const getOffers = async (currency: string, amountReq?: number, offs?: Offers) => {
        if (amountReq) {
            console.dir(offs);
            console.log(amountReq, Number(offs?.products[0]?.limits?.minimum), Number(offs?.products[0]?.limits?.maximum))
            if (amountReq >= Number(offs?.products[0]?.limits?.minimum) && amountReq <= Number(offs?.products[0]?.limits?.maximum)) {
                console.log('right')
                setAmountRequested(amountReq);
                setoutOfBounds(false);
            }
            else {
                console.log('less')
                setoutOfBounds(true);
                return;
            }
        }
        const location = window.location.toString().replace('#', '/');
        const merchantId = new URL(location).searchParams.get('merchantId');

        setLoading(true);
        const token = await axios.post(`${config?.api.base}/token`, {
            client_id: config?.api.credentials.client_id,
            client_secret: config?.api.credentials.client_secret,
            grant_type: config?.api.credentials.grant_type,
        })

        const res = await axios.post(`${config?.api.base}/offers`, baseApplicationFromPrepopulation(props.prepopData, amountReq), { headers: { 'Authorization': `Bearer ${token.data.access_token}` } })
        if (res.data) {
            setOffers(res.data);
            setSelectedOffer(res.data.products[0].offers[0]);
            setCustomSplit(false);

            if (!amountReq) {
                setAmountRequested(res.data.products[0].limits.maximum);
                setInputValue(res.data.products[0].limits.maximum)
            }

            setLoading(false);
        }
    }

    const debouncedSearch = useRef(
        debounce(getOffers, 1000)
    );

    const uppercase = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }


    const handleChange = (value: number) => {
        debouncedSearch.current(props.currency, value, offers);
    };


    useEffect(() => {
        if (!offers) {
            console.log(amountRequested);
            debouncedSearch.current(props.currency ?? 'GBP', amountRequested, offers);

            return () => {
                debouncedSearch.current.cancel();
            };
        }
    }, [offers])

    const format = (currency: string, val: string) => `${formatCurrency(currency)}${val}`


    return (
        <Box>
            <Box w='100%' height={'60px'}><Image pos={'absolute'} top={10} right={10} src={liberiscombinedlogo} /></Box>

            <Container marginTop={'2%'} maxW={'lg'} minH={'100vh'}>
                {!offers?.products &&

                    <Modal isCentered isOpen={true} onClose={() => { }}>
                        <ModalOverlay />
                        <ModalContent height={'473px'} width={'480px'}>
                            <ModalBody>
                                <AbsoluteCenter textAlign={'center'}>
                                    <Stack spacing={1}>
                                        <Text margin={'0 auto'} fontSize={'18px'} fontWeight={'600'}>Loading your quote</Text>
                                        <Text margin={'0 auto'} color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>We're personalizing your quote</Text>
                                        <Spinner margin={'0 auto'} marginTop={'20px'} />
                                    </Stack>
                                </AbsoluteCenter>
                            </ModalBody>

                        </ModalContent>
                    </Modal>

                }

                {
                    offers?.products && <Stack spacing={8}>


                        <Image margin={'0 auto'} src={progresstwo} />

                        <Stack>
                            <Text margin={'0 auto'} color={'#0E0B23'} fontSize={'24px'} fontWeight={'600'}>Now choose your funding amount</Text>

                            <Text margin={'0 auto'} textAlign={'center'} fontSize={'14px'} fontWeight={'400'} color={'#404A70'}>Select the funding amount and payment speed that is right for your business.</Text>
                            <Stack spacing={4}>
                                <Image marginTop={'50px'} w={'70px'} src={stepone}></Image>
                                <Text fontSize={'18px'} fontWeight={'700'}>Select funding amount</Text>
                                <InputGroup w={'100%'} borderRadius={'8px'} border={`1px solid ${primary}`}>
                                    <InputRightElement
                                        pointerEvents='none'
                                        color='black'
                                        fontSize='1.2em'
                                        children={<Image src={edit} />}
                                    />
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='black'
                                        fontSize='1.2em'
                                        children={<Image src={dollar} />}
                                    />
                                    <NumberInput
                                        onChange={(valueString) => { setInputValue(Number(valueString)); setSliderValue(Number(valueString)); handleChange(Number(valueString)) }}
                                        max={offers.products[0].limits.maximum}
                                        value={inputValue}
                                        defaultValue={offers.products[0].limits.maximum.toString()}
                                        w={'100%'}
                                        marginLeft={'35px'}
                                        variant={'flushed'}
                                    >
                                        <NumberInputField border={'none'}></NumberInputField>
                                    </NumberInput>
                                </InputGroup>
                                {outOfBounds && <Text fontSize={'14px'} color={'red'}>Enter a value between {format(offers.products[0]?.offers[0].currency, offers.products[0].limits.minimum.toString())} and {format(offers.products[0]?.offers[0].currency, offers.products[0].limits.maximum.toString())} </Text>}
                            </Stack>

                            <Slider margin={'30px 0 0px 0'} onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)} onChange={(e) => { handleChange(e); setSliderValue(e); setInputValue(e) }} defaultValue={offers.products[0].limits.maximum} max={offers.products[0].limits.maximum} min={offers.products[0].limits.minimum} step={1000} colorScheme="purple" aria-label='slider-ex-1'>
                                <SliderTrack bg={primary}>
                                    <SliderFilledTrack bg={primary} />
                                </SliderTrack>
                                <Tooltip
                                    hasArrow
                                    color='white'
                                    placement='top'
                                    isOpen={showTooltip}
                                    label={`${sliderValue}`}
                                >
                                    <SliderThumb bg={primary} boxSize={6}>
                                        <Box bg={primary} />
                                    </SliderThumb>
                                </Tooltip>

                            </Slider>

                            <Box pos={'relative'}>
                                <Box pos={'absolute'} left={0}>
                                    <Text color={'#6C728A'} size={'14px'} fontWeight={'400'}>Min: ${offers.products[0].limits.minimum}</Text>
                                </Box>
                                <Box pos={'absolute'} right={0}>
                                    <Text color={'#6C728A'} size={'14px'} fontWeight={'400'}>Max: ${offers.products[0].limits.maximum}</Text>
                                </Box>
                            </Box>

                            <Stack spacing={4}>
                                <Image marginTop={'50px'} w={'70px'} src={steptwo}></Image>
                                <Text fontSize={'18px'} fontWeight={'700'}>Choose your offer</Text>
                            </Stack>

                            <Stack margin={'0 auto'} direction={'row'} marginTop={'10px'} spacing={7}>
                                {offers.products[0].offers.map((offer, index) => {
                                    return <Stack key={offer.offer_id} direction={'row'}>
                                        <Skeleton key={offer.offer_id} isLoaded={!loading}>
                                            <Stack cursor={'pointer'} onClick={() => { setSelectedOffer(offer) }}>
                                                <Box>
                                                    <Text fontWeight={'700'} color={'#404A70'} fontSize={'14px'}>OFFER {index + 1}:</Text>
                                                    <Text fontWeight={'700'} color={'#0E0B23'} fontSize={'14px'}>{offer.description[0].toUpperCase() + offer.description.slice(1)}</Text>
                                                </Box>
                                                <Box p='20px' borderRadius={'8px'} border={'1px solid #00000063'} bg={selectedOffer?.offer_id === offer.offer_id ? '#0E0B23' : 'white'} color={selectedOffer?.offer_id === offer.offer_id ? 'white' : 'black'}>
                                                    <Stack>
                                                        <Box>
                                                            <Text fontWeight={'600'} fontSize={'14px'}>Fixed fee</Text>
                                                            <Text fontWeight={'600'} fontSize={'14px'} color={selectedOffer?.offer_id === offer.offer_id ? '#D9DBE2' : '#525252'}>${offer.repayment_amount - offer.funded_amount}</Text>
                                                        </Box>
                                                        <Box>
                                                            <Text fontWeight={'600'} fontSize={'14px'}>Split (daily %)</Text>
                                                            <Text fontWeight={'600'} fontSize={'14px'} color={selectedOffer?.offer_id === offer.offer_id ? '#D9DBE2' : '#525252'}>{offer.split_percentage}</Text>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Skeleton>
                                    </Stack>
                                })}
                            </Stack>
                        </Stack>


                        <Card border={'1px solid #00000063'} borderRadius={'20px'} top={0} >

                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>

                                <Tabs margin={'0 auto'} variant='soft-rounded' colorScheme='gray'>
                                    <Stack padding={'20px'} spacing={5}>
                                        <TabList textAlign={'center'}>
                                            <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >Review quote terms</Tab>
                                            <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >How does it work?</Tab>
                                        </TabList>
                                    </Stack>
                                    <TabPanels>
                                        <TabPanel p={'30px'}>
                                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                                <Text fontSize={'19px'} fontWeight={'400'} size='md'>Quote terms</Text>
                                                <Text fontWeight={'400'} fontSize={'15px'} color={'#404A70'}>Select the funding amount and payment speed that is right for your business.</Text>
                                            </Skeleton>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>



                            </Skeleton>
                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Payment terms</Text></Box>
                            </Skeleton>
                            <Box padding={'20px 20px 20px 30px'}>
                                <Stack spacing={5}>
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={2}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Split (%)</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={2}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.split_percentage ? `${selectedOffer?.split_percentage}% of daily sales` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>

                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={2}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Estimated completion</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={2}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.term_length ? `${selectedOffer?.term_length} months` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                </Stack>
                            </Box>
                            <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Total funding amount</Text></Box>
                            </Skeleton>
                            <Box padding={'20px 20px 10px 30px'}>
                                <Stack spacing={5}>
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={3}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Funding amount</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={1}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.funded_amount ? `${formatCurrency(selectedOffer.currency, selectedOffer.funded_amount)}` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={3}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Fixed fee</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={1}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer ? `${formatCurrency(selectedOffer.currency, Number(selectedOffer?.repayment_amount) - Number(selectedOffer?.funded_amount))}` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                    <Divider />
                                    <Skeleton speed={0} fadeDuration={1} isLoaded={!!selectedOffer}>
                                        <Grid marginBottom={'20px'} templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                            <GridItem colSpan={3}>
                                                <Text fontWeight={'400'} fontSize={'17px'}>Total to pay</Text>
                                            </GridItem>
                                            <GridItem textAlign={'right'} colSpan={1}>
                                                <Text fontWeight={'bold'} fontSize={'17px'}>{selectedOffer?.repayment_amount ? `${formatCurrency(selectedOffer.currency, selectedOffer.repayment_amount)}` : '??'}</Text>
                                            </GridItem>
                                        </Grid>
                                    </Skeleton>
                                </Stack>
                            </Box>
                        </Card>

                        <Image src={approvalcard}></Image>

                        <Button isDisabled={!selectedOffer} onClick={() => { props.setOfferId(selectedOffer); props.setPage(Page.Contract); window.scrollTo(0, 0); }} fontWeight={'400'} bg={primary} color={selectedOffer ? 'white' : '#0E0B23'} borderRadius={'25px'} h='35px'>Confirm and continue</Button>
                        <Image marginTop={'20%'} src={footer} />

                    </Stack >
                }

            </Container >
        </Box>
    )
}


const ThankyouLatest = (props: { offer?: Offer }) => {
    const primary = '#0E0B23';

    const steps = [
        { title: 'Application approved!', description: 'One of our finance experts will reach out to discuss your application.' },
        { title: 'Awaiting funds', description: 'Typically once approved, you should receive funds within 24 - 48 hours.' },
        { title: 'Funding payments begin', description: 'Once you’ve paid 50%, you’ll be able to renew to get more finance when you need it.' },
    ]

    const { activeStep } = useSteps({
        index: 1,
        count: steps.length,
    })

    return (<Box>
        <Box w='100%' height={'60px'}><Image pos={'absolute'} top={10} right={10} src={liberiscombinedlogo} /></Box>

        <Container marginTop={'2%'} maxW={'lg'}>
            <Stack spacing={10}>
                <Image margin={'0 auto'} src={progressfour} />

                <Text textAlign={'center'} margin={'0 auto'} color={'#0E0B23'} fontSize={'24px'} fontWeight={'600'}>Funding on its way</Text>
                <Text textAlign={'center'} fontWeight={'400'} color={'#404A70'} fontSize={'14px'}>Congratulations your quote has been approved and finance is on its way to your account.</Text>

                <Box p={5}>
                    <Stack spacing={10}>
                        <Stack spacing={5}>
                            <Text fontWeight={'700'} fontSize={'16px'}>What happens next?</Text>
                            <Text fontWeight={'400'} color={'#404A70'} size={'16px'}>Your application has been approved and funding is on it’s way. Check back soon to track payments.</Text>
                        </Stack>
                        <Stepper size={'sm'} colorScheme="green" index={activeStep} orientation='vertical' height='200px' gap='0'>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<Box bg={'#3FBE58'} borderRadius={'20px'}><Center><StepIcon h='22px' w='22px' /></Center></Box>}
                                            incomplete={<Box />}
                                            active={<Box />}
                                        />
                                    </StepIndicator>
                                    <Box>
                                        <StepTitle><Text fontSize={'16px'} color='#404A70' fontWeight={'400'}>{step.title}</Text></StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                </Box>
                <Button margin={'17% 0 5% 0'} onClick={() => { }} bg={primary} color={'white'} fontSize={'14px'} fontWeight={'400'} borderRadius={'25px'} h='35px' w={'100%'}>View my dashboard</Button>

            </Stack>
        </Container>
    </Box>)
}

const ContractsLatest = (props: { setPage: any, offer?: Offer }) => {
    const primary = '#0E0B23';
    const [loading, setLoading] = useState<boolean>();
    const [contractLink, setContractLink] = useState<string>();
    const [frameHeight, setFrameHeight] = useState<number>(0)
    const [viewContract, setViewContract] = useState<boolean>(false)
    const [contractSigned, setContractSigned] = useState<boolean>(false)

    const config = useConfig()

    useEffect(() => {

        window.addEventListener(
            "message",
            function (event) {
                if (typeof event.data == 'string') {
                    try {
                        const message = JSON.parse(event.data);
                        if (message.containerHeight && message.containerHeight !== frameHeight) {
                            console.log(message.containerHeight);
                            setFrameHeight(message.containerHeight)
                        }
                        if (message.status === 'ContractComplete') {
                            setViewContract(false)
                        }
                        if (message.status === 'SigningComplete') {
                            setContractSigned(true);
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            },
            false,
        );

        const acceptOffer = async () => {
            setLoading(true);
            const token = await axios.post(`${config?.api.base}/token`, {
                client_id: config?.api.credentials.client_id,
                client_secret: config?.api.credentials.client_secret,
                grant_type: config?.api.credentials.grant_type,
            })

            const res = await axios.post(`${config?.api.base}/offers/${props.offer?.offer_id}/accept`, null, { headers: { 'Authorization': `Bearer ${token.data.access_token}` } })
            if (res.data) {
                setContractLink(res.data.links.contract_link);
                console.log(res.data);
                setLoading(false);
            }
        }

        const contractLink = localStorage.getItem('contractlink');
        if (!contractLink) {
            acceptOffer();
        } else {
            setContractLink(contractLink);
        }
    }, [])

    return (
        <Box>
            <Modal isCentered size={'3xl'} onClose={() => { setViewContract(false) }} isOpen={viewContract}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody p='0' minHeight={'473px'} minWidth={'480px'}>
                        {(frameHeight === 0) && <AbsoluteCenter textAlign={'center'}>
                            <Stack spacing={1}>
                                <Text margin={'0 auto'} fontSize={'18px'} fontWeight={'600'}>Loading your contract</Text>
                                <Spinner margin={'0 auto'} marginTop={'20px'} />
                            </Stack>
                        </AbsoluteCenter>}
                        <iframe scrolling="no" height={frameHeight} width={'100%'} src={contractLink}></iframe>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Container marginTop={'2%'} maxW={'lg'}>

                <Image margin={'0 auto'} src={progressthree} />

                <Text textAlign={'center'} margin={'0 auto'} color={'#0E0B23'} fontSize={'24px'} fontWeight={'600'}>Lastly, sign your contract</Text>
                <Text textAlign={'center'} fontWeight={'400'} color={'#404A70'} fontSize={'14px'}>You can review and sign your contract to secure your finance.</Text>

                <Card margin={'10% 0 10% 0'} borderRadius={'9px'} padding={'30px'} border='1px solid #9C9C9C'>
                    <Text fontWeight={'600'} fontSize={'16px'}>Revenue based Finance Agreement</Text>
                    <Text color={contractSigned ? '#3FBE58' : '#404A70'} fontWeight={'600'} fontSize={'14px'}>{contractSigned ? 'Document signed' : 'Not yet signed'}</Text>
                    {!contractSigned && <Button marginTop={'20px'} borderRadius={'15px'} fontWeight={'400'} fontSize={'13px'} bg='#0E0B23' w='170px' h='30px' color={'white'} onClick={() => { setViewContract(true) }}>Sign</Button>}
                    {contractSigned && <Box textAlign={'right'}><Button variant={"link"} marginTop={'20px'} borderRadius={'15px'} fontWeight={'600'} fontSize={'16px'} w='170px' h='30px' color={'#0E0B23'} onClick={() => { setViewContract(true) }}>Review agreement <ChevronRightIcon boxSize={'22px'} /></Button></Box>}

                    <CheckCircleIcon color={contractSigned ? '#3FBE58' : '#D9D9D9'} boxSize={'30px'} w='35px' pos={'absolute'} top='5' right='5' />
                </Card>
                <Stack>
                    <Card border={'1px solid #00000063'} borderRadius={'20px'} top={0} >

                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>

                            <Tabs margin={'0 auto'} variant='soft-rounded' colorScheme='gray'>
                                <Stack padding={'20px'} spacing={5}>
                                    <TabList textAlign={'center'}>
                                        <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >Review quote terms</Tab>
                                        <Tab color={'#000000'} fontWeight={'600'} w={'50%'} >How does it work?</Tab>
                                    </TabList>
                                </Stack>
                                <TabPanels>
                                    <TabPanel p={'30px'}>
                                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                            <Text fontSize={'19px'} fontWeight={'400'} size='md'>Quote terms</Text>
                                            <Text fontWeight={'400'} fontSize={'15px'} color={'#404A70'}>Select the funding amount and payment speed that is right for your business.</Text>
                                        </Skeleton>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>



                        </Skeleton>
                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                            <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Payment terms</Text></Box>
                        </Skeleton>
                        <Box padding={'20px 20px 20px 30px'}>
                            <Stack spacing={5}>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={2}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Split (%)</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={2}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.split_percentage ? `${props.offer?.split_percentage}% of daily sales` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>

                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={2}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Estimated completion</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={2}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.term_length ? `${props.offer?.term_length} months` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                            </Stack>
                        </Box>
                        <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                            <Box padding={'8px 0 0 30px'} bg='#ECEDF1' h={'40px'}><Text color={'#404A70'} fontWeight={'400'} fontSize={'15px'}>Total funding amount</Text></Box>
                        </Skeleton>
                        <Box padding={'20px 20px 10px 30px'}>
                            <Stack spacing={5}>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Funding amount</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.funded_amount ? `${formatCurrency(props.offer.currency, props.offer.funded_amount)}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Fixed fee</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer ? `${formatCurrency(props.offer.currency, Number(props.offer?.repayment_amount) - Number(props.offer?.funded_amount))}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                                <Divider />
                                <Skeleton speed={0} fadeDuration={1} isLoaded={!!props.offer}>
                                    <Grid marginBottom={'20px'} templateColumns='repeat(4, 1fr)' gap={'10px'}>
                                        <GridItem colSpan={3}>
                                            <Text fontWeight={'400'} fontSize={'17px'}>Total to pay</Text>
                                        </GridItem>
                                        <GridItem textAlign={'right'} colSpan={1}>
                                            <Text fontWeight={'bold'} fontSize={'17px'}>{props.offer?.repayment_amount ? `${formatCurrency(props.offer.currency, props.offer.repayment_amount)}` : '??'}</Text>
                                        </GridItem>
                                    </Grid>
                                </Skeleton>
                            </Stack>
                        </Box>
                    </Card>

                    <Button marginTop={'15%'} isDisabled={!contractSigned} onClick={() => { props.setPage(Page.Thankyou); window.scrollTo(0, 0); }} fontWeight={'400'} bg={primary} color={props.offer ? 'white' : '#0E0B23'} borderRadius={'25px'} h='35px'>Submit application</Button>
                    <Image marginTop={'20%'} src={footer} />

                </Stack >
            </Container>
        </Box >)
}

const ReviewLatest = (props: { setPage: any, prepopData?: PrepopulationData }) => {

    const [viewBusiness, setViewBusiness] = useState<boolean>()
    const [viewPersonal, setViewPersonal] = useState<boolean>()

    return (<Box>
        <Box w='100%' height={'60px'}><Image pos={'absolute'} top={10} right={10} src={liberiscombinedlogo} /></Box>
        <Container marginTop={'2%'} maxW={'lg'}>


            <Stack spacing={10}>
                <Image margin={'0 auto'} src={progressone} />
                <Stack textAlign={'center'} >
                    <Text margin={'0 auto'} fontSize={'24px'} fontWeight={'600'}>First, lets check your details are all correct</Text>
                    <Text margin={'0 auto'} w={'90%'} fontSize={'14px'} fontWeight={'400'}>We’ll use the details below to calculate your offer and fees, which we’ll share with Liberis.</Text>
                </Stack>
            </Stack>

            <Box cursor={'pointer'} onClick={() => setViewBusiness(!viewBusiness)} pos={'relative'} marginTop={'10%'} p='25px' borderBottom={'1px solid #B3B7C6'}>
                <HStack>
                    <Image src={storecircle} />
                    <Text marginLeft={'25px'} fontWeight={'400'} color={'#404A70'} fontSize={'16px'}>Business details</Text>
                    {props.prepopData && <Text marginLeft={'120px'} fontWeight={'400'} color={'green'} fontSize={'16px'}>Complete</Text>}
                    {!props.prepopData && <Text marginLeft={'110px'} fontWeight={'400'} color={'red'} fontSize={'16px'}>Incomplete</Text>}

                    {viewBusiness ? <ChevronDownIcon pos={'absolute'} right={10} boxSize={8} /> : <ChevronUpIcon pos={'absolute'} right={10} boxSize={8} />}
                </HStack>
            </Box>


            {viewBusiness && <Box padding={'30px'} bg={'#FAFAFA'}>
                <SimpleGrid columns={2}>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Business Entity</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.business_type ?? '?'}</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Company name</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.legal_name ?? '?'}</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Business start date</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.business_start_date ?? '?'}</Text>
                        </Box>
                    </Stack>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Registered Address</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.registered_address.line1 ?? '?'}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.registered_address.line2}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.registered_address.town_city}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.registered_address.postcode}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.company.registered_address.country}</Text>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Box>}


            <Box cursor={'pointer'} onClick={() => setViewPersonal(!viewPersonal)} pos={'relative'} p='25px' borderBottom={'1px solid #B3B7C6'}>
                <HStack>
                    <Image src={personcircle} />
                    <Text marginLeft={'25px'} fontWeight={'400'} color={'#404A70'} fontSize={'16px'}>Your details</Text>
                    {props.prepopData && <Text marginLeft={'150px'} fontWeight={'400'} color={'green'} fontSize={'16px'}>Complete</Text>}
                    {!props.prepopData && <Text marginLeft={'140px'} fontWeight={'400'} color={'red'} fontSize={'16px'}>Incomplete</Text>}

                    {viewPersonal ? <ChevronDownIcon pos={'absolute'} right={10} boxSize={8} /> : <ChevronUpIcon pos={'absolute'} right={10} boxSize={8} />}
                </HStack>
            </Box>
            {viewPersonal && <Box padding={'30px'} bg={'#FAFAFA'}>
                <SimpleGrid columns={2}>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Full name</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].first_name ?? '?'}</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Phone number</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].telephone_number ?? '?'}</Text>
                        </Box>

                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Email address</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].email_address ?? '?'}</Text>
                        </Box>
                    </Stack>
                    <Stack spacing={'20px'}>
                        <Box>
                            <Text color={'#404A70'} fontWeight={'400'} fontSize={'14px'}>Home Address</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].residences.address.line1 ?? '?'}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].residences.address.line2}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].residences.address.town_city}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].residences.address.postcode}</Text>
                            <Text color={'#0E0B23'} fontWeight={'400'} fontSize={'16px'}>{props.prepopData?.applicants[0].residences.address.country}</Text>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Box>}


            <Box padding={'20px'}>
                <Text margin={'30px 0 10px 0'} fontWeight={'600'} fontSize={'14px'} color={'#404A70'}>Marketing</Text>
                <Text fontWeight={'400'} fontSize={'14px'} color={'#6C728A'}>
                    Please check this box if you are happy for Liberis to contact you, using the information you provided all about their products and their selected partners’ product and services.
                </Text>
                <Checkbox p={'20px 0 0 10px'}><Text fontSize={'14px'}>I consent</Text></Checkbox>

                <Button margin={'17% 0 5% 0'} onClick={() => { props.setPage(Page.Offers); window.scrollTo(0, 0); }} bg='#0E0B23' color={'white'} fontSize={'14px'} fontWeight={'400'} borderRadius={'25px'} h='35px' w={'100%'}>Get funding</Button>
                <Text textAlign={'center'} fontWeight={'400'} fontSize={'12px'} color={'#404A70'}>
                    By continuing, you accept that we’ll share your information with credit reference and fraud prevention agencies for security reasons. To see how your data will be used, please take a look at Liberis’ Privacy Policy.
                </Text>
            </Box>

            <Image marginTop={'20%'} src={footer} />

        </Container >
    </Box >)
}

export default ExampleJourneyPrepopulation