import { InputGroup, InputLeftAddon, Tag, Checkbox, Alert, AlertIcon, Box, Button, Card, CardBody, CardHeader, Center, Code, Container, Divider, FormControl, FormLabel, Grid, GridItem, Heading, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spinner, Stat, StatLabel, StatNumber, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast, Image, Stack, Stepper, Step, StepIndicator, StepStatus, StepTitle, StepDescription, StepSeparator, Skeleton, useColorMode, Switch, HStack, useColorModeValue, OrderedList, ListItem, UnorderedList, TagLeftIcon, TagLabel, AbsoluteCenter, StatArrow, StatGroup, StatHelpText, InputLeftElement } from "@chakra-ui/react"
import { ArrowForwardIcon, ArrowBackIcon, CheckIcon, ChevronDownIcon, CloseIcon, CopyIcon, DeleteIcon, ExternalLinkIcon, ChevronRightIcon, AddIcon } from '@chakra-ui/icons'
import logo from './logo.svg';
import logomarq from './logo-marq.png';
import lightlogo from './lightlogo.svg';
import { ReactComponent as OverviewLogo } from './overview.svg';
import { ReactComponent as MerchantsLogo } from './merchants.svg';
import { ReactComponent as WebhooksLogo } from './webhooks.svg';
import { ReactComponent as DataSharingLogo } from './datasharing.svg';
import { ReactComponent as MarketingLogo } from './marketing.svg';
import cross from './cross.svg'
import scale from './scale.svg'
import { ReactComponent as KnowledgeLogo } from './brain.svg';
import { ReactComponent as DocumentationLogo } from './documentation.svg';
import storefront from './storefront.svg'
import liberisoutline from './liberisoutline.svg'
import liberisoutlinelight from './liberisoutline-light.svg'
import wave from './wave.png'
import './App.css';
import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { JsonViewer, JsonViewerTheme } from '@textea/json-viewer'
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import copy from 'copy-to-clipboard';
import {
  HashRouter,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import './index.css';
import GuidedOnboarder, { ApplicationSteps } from "./components/GuidedOnboarder";
import TailoredMarketingPage from "./components/TailoredMarketingPage";
import DefaultMarketingPage from "./components/DefaultMarketingPage";
import DataSharingPage from "./components/DataSharingPage";
import KnowledgeBasePage, { downloadCollection } from "./components/KnowledgeBasePage";
import ContractPage from "./components/ContractPage";
import "driver.js/dist/driver.css";
import MerchantComponent from "./components/Merchant";
import ExampleJourney from "./components/ExampleJourney";
import { ConfigProvider } from "./providers/ConfigProvider";
import { useConfig } from "./hooks/useConfig";
import PricingPage from "./components/Pricing";
import FlexiMerchant from "./components/FlexiMerchant";
import { FlexiAccount } from "./models/account";
import { FlexiAdvance } from "./models/advance";
import ExampleJourneyPrepopulation, { PrepopulationData } from "./components/ExampleJourneyPrepopulation";



export enum Page {
  Overview = 'Overview',
  Merchants = 'Merchants',
  Merchant = 'Merchant',
  Offers = 'Offers',
  Prepopulation = 'Prepopulation',
  Webhooks = 'Webhooks',
  Marketing = 'Marketing',
  TailoredMarketing = 'Tailored adverts',
  DefaultMarketing = 'Default adverts',
  DataSharing = 'Data Sharing',
  Contracts = 'contracts',
  Pricing = 'Pricing'
}

export enum PaymentMethod {
  LiberisCreated = 'Liberis created payment',
  PartnerCreated = 'Partner split payment'
}

export enum MerchantStage {
  Created = "Created",
  OffersCreated = "OffersCreated",
  OffersCustomised = "OffersCustomised",
  ApplicationCreated = "ApplicationCreated",
  DealCreated = "DealCreated",
  AccountCreated = "AccountCreated",
  ContractSigned = "ContractSigned",
  AdvanceOffersCreated = "AdvanceOffersCreated",
  AdvanceCreated = "AdvanceCreated",
  PaymentsCreated = "PaymentsCreated"
}


export enum Decision {
  Approved = 'Approved',
  Declined = 'Declined',
  Referred = 'Referred'
}

export enum MerchantRepayment {
  PartnerSplit = 'Partner Split',
  LiberisSplit = 'Liberis Split',
  HybridSplit = 'Hybrid Split',
}

export enum ProductType {
  BCA = 'BCA',
  Flexi = 'Flexi'
}

export enum WebhookType {
  NoAuth = 'No Auth',
  Basic = 'Basic Auth',
  Oauth2 = 'OAuth2',
  Oauth2ClientCreds = 'OAuth2 Client Credentials'
}

export interface Merchant {
  merchantId: string,
  currency: string,
  product: ProductType,
  limits: {
    minimum: number,
    maximum: number
  },
  webhookUrl?: string,
  webhookType?: string,
  webhookSecret?: string,
  webhookClient?: string,
  webhookHMAC?: string,
  webhookTokenPayload?: string,
  webhookTokenEndpoint?: string,
  created?: Date,
  stage?: MerchantStage,
  decision: Decision,
  partner: string,
  paymentMechanism: string,
  liberis_id?: string,
  nearestPricing: boolean,
  contractEnabled: boolean
}

export interface Offer {
  offer_id: string,
  offer_status: string,
  description: string,
  split_percentage: number,
  term_length: number,
  funded_amount: number,
  repayment_amount: number,
  factor_rate: number,
  currency: string,
  merchantId: string
}

export interface Application {
  merchantId: string,
  status: string,
  data: any,
  offers: Offer[]
}

export interface Transaction {
  principle_repaid: number
  repay_amount: number,
  repay_rate: number,
  transaction_amount: number,
  transaction_date: Date,
  transaction_type: string,
  payment_channel_id: string
}

export interface Deal {
  id: string,
  merchantId: string,
  application_id: string,
  liberis_id: string,
  type: string,
  status: string,
  active: boolean,
  balance: number,
  currency: string,
  performance: {
    performance: number,
    progress: number,
    renewal_eligibility: boolean
  },
  pricing: {
    repayment_amount: number,
    funded_amount: number,
    split_percent: number,
    factor_rate: number,
    estimated_length_months: number
  },
  transactions: Transaction[]
}

export interface ClaimsData {
  partner_client_id: string,
  client_start_date: Date,
  entity_type: string,
  other_identifiers: [
    {
      key: string,
      values: string[]
    }
  ],
  revenue: [
    {
      time_period: {
        start_time: Date,
        end_time: Date,
      },
      total_amount: number,
      currency: string,
      transaction_count: number,
      industry_category: {
        scheme: string,
        code: string
      },
      other_identifiers: [
        {
          key: string,
          values: [
            string
          ]
        }
      ]
    }
  ]
}


export interface FlexiApplication {
  id: string;
  merchantId: string;
  contractStep: string,
  status: string,
  referringPartnerCode: string;
  applicants: Applicant[];
  company: Company;
  consent: Consent;
  intendedUseOfFunds: string;
  accountDetails: AccountDetails;
  currency: string;
  bankDetails: BankDetails;
  liberis_id: string;
}

interface Applicant {
  identityDocuments: IdentityDocument[];
  firstName: string;
  lastName: string;
  residences: Residence[];
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  ownershipPercentage: number;
  isPrimaryApplicant: boolean;
}

interface IdentityDocument {
  type: string;
  countryOfIssue: string;
  number: string;
}

interface Residence {
  address: Address;
  movedInDate: string;
  movedOutDate: string;
}

interface Address {
  lineOne: string;
  lineTwo: string;
  city: string;
  postCode: string;
  state: string;
  country: string;
}

interface Company {
  referringPartnerExternalId: string;
  liberisId: string;
  accountId: string;
  otherPartnerExternalId: OtherPartnerExternalId[];
  businessType: string;
  phoneNumber: string;
  email: string;
  registrationNumber: string;
  registeredName: string;
  tradingAddress: Address;
  registeredAddress: Address;
  tradingName: string;
  sector: string;
}

interface OtherPartnerExternalId {
  code: string;
  externalId: string;
}

interface Consent {
  isCreditSearch: boolean;
  isProductCommsAndMarketing: boolean;
  isRenewalsCommsAndMarketing: boolean;
}

interface AccountDetails {
  size: number;
  split: number;
}

interface BankDetails {
  bankName: string;
  routingNumber: string;
  bankAccountNumber: string;
}


export interface Webhook {
  id: string,
  merchantId: string,
  event: string,
  success: boolean,
  code: number,
  webhookUrl: string,
  created: Date,
  data: {
    id: string,
    api_version: number,
    event: string,
    created_date: Date,
    data: {
      currency: string,
      created_date: Date,
      split_percent: number,
      references: {
        application: string,
        merchant: string,
        deal: string,
        external_merchant: string
      },
      actions: {
        notify: string
      }
    }
  }
}

export interface Context {
  userId?: string
}


export const AppContext = createContext({} as Context);


function App() {
  const { user, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [userId, setUserId] = useState<string>();
  const [page, setPage] = useState<Page>(Page.Overview);
  const [merchant, setMerchant] = useState<Merchant>();
  const toast = useToast();

  const connectionLost = () => {
    const toastId = 'toast1';
    if (!toast.isActive(toastId)) {
      toast({
        title: 'Lost connection to server',
        description: "Attempting to re-establish...",
        status: 'warning',
        id: toastId,
        duration: 9000,
        isClosable: false
      })
    }
  }

  useEffect(() => {

    const pageRoutes = [
      { page: Page.Overview, route: '/' },
      { page: Page.DataSharing, route: '/data-sharing' },
      { page: Page.Marketing, route: '/marketing' },
      { page: Page.Marketing, route: '/marketing/tailored' },
      { page: Page.Marketing, route: '/marketing/default' },
      { page: Page.Merchants, route: '/merchants' },
      { page: Page.Merchants, route: '/merchant' },
      { page: Page.Webhooks, route: '/webhooks' },
      { page: Page.Contracts, route: '/contract' },
      { page: Page.Prepopulation, route: '/prepopulation' }
    ]

    const getCurrentPage = () => {
      let location = window.location.hash.replace('#', '').split('?')[0];

      if (!location) {
        location = window.location.pathname
      }

      const pageFromUrl = pageRoutes.find(x => x.route === location)?.page;
      setPage(pageFromUrl ?? Page.Overview);
      return pageFromUrl;
    }


    const page = getCurrentPage();
    if (!isAuthenticated && !isLoading && page !== Page.Contracts && page !== Page.Prepopulation) loginWithRedirect();
    if (user) setUserId(user.sub)
  }, [isAuthenticated, loginWithRedirect, isLoading, user])


  if (!isAuthenticated) {
    return (<AppContext.Provider value={{ userId }}>
      <HashRouter basename='/'>
        <Routes>
          <Route path='/contract' element={<ContractPage />}></Route>
          <Route path='/prepopulation' element={<ExampleJourneyPrepopulation />}></Route>
        </Routes>

      </HashRouter>
    </AppContext.Provider>
    )
  }
  if (isAuthenticated) {
    return (
      <AppContext.Provider value={{ userId }}>
        <HashRouter basename='/'>
          <Routes>
            <Route path='/contract' element={<ContractPage />}></Route>
            <Route path='/prepopulation' element={<ExampleJourneyPrepopulation />}></Route>
            <Route path="/" element={<Layout setPage={setPage} page={page} component={<Home setPage={setPage} />} />}></Route>
            <Route path="/data-sharing" element={<Layout setPage={setPage} page={page} component={<DataSharingPage />} />}></Route>
            <Route path="/marketing/tailored" element={<Layout setPage={setPage} page={page} component={<TailoredMarketingPage />} />}></Route>
            <Route path="/marketing/default" element={<Layout setPage={setPage} page={page} component={<DefaultMarketingPage />} />}> </Route>
            <Route path="/merchants" element={<Layout setPage={setPage} page={page} component={<Merchants setPage={setPage} setMerchant={setMerchant} userId={userId} connectionLost={connectionLost} />} />}> </Route>
            <Route path="/webhooks" element={<Layout setPage={setPage} page={page} component={<Webhooks userId={userId} connectionLost={connectionLost} />} />}> </Route>
            <Route path="/merchant" element={<Layout setPage={setPage} page={page} component={<MerchantComponent setPage={setPage} scopedMerchant={merchant} userId={userId} connectionLost={connectionLost} />} />}> </Route>
            <Route path="/flexi-merchant" element={<Layout setPage={setPage} page={page} component={<FlexiMerchant setPage={setPage} scopedMerchant={merchant} userId={userId} connectionLost={connectionLost} />} />}> </Route>
            <Route path="/knowledge" element={<Layout setPage={setPage} page={page} component={<KnowledgeBasePage />} />}> </Route>
            <Route path="/journey" element={<Layout setPage={setPage} page={page} component={<ExampleJourney />} />}> </Route>
            <Route path="/pricing" element={<Layout setPage={setPage} page={page} component={<PricingPage />} />}> </Route>
            <Route path="/creating-an-application" element={<Layout setPage={setPage} page={page} component={<GuidedOnboarder
              title='Creating an application for a merchant.'
              subTitle="Creating an initial application for a merchant is the first step in financing via Liberis Create."
              timeRequired={10}
              objectives={["Create a merchant record",
                "Get offers for a merchant",
                "Customise the amount of funding a merchant requires",
                "Accept an offer for a merchant",
                "Set up a repayment method",
                "Funds automatically disbursed to the merchant"]}
              guidedSteps={ApplicationSteps}
            />} />}> </Route>
            <Route path='*' element={<Layout setPage={setPage} page={page} component={<Home setPage={setPage} />} />}></Route>
          </Routes>

        </HashRouter>
      </AppContext.Provider>
    );
  }
  else {
    return (<AppContext.Provider value={{ userId }}>
      <HashRouter basename='/'>
        <Routes>
          <Route path='/contract' element={<ContractPage />}></Route>
          <Route path='/prepopulation' element={<ExampleJourneyPrepopulation />}></Route>
          <Route path="/" element={<Layout setPage={setPage} page={page} component={<Home setPage={setPage} />} />}></Route>
        </Routes>

      </HashRouter>
    </AppContext.Provider>
    )
  }
}

const Layout = (props: { setPage: any, page: any, component: any }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const { logout } = useAuth0();
  const config = useConfig()
  const [hideNav, setHideNav] = useState<boolean>(false);
  const [developerClicks, setDeveloperClicks] = useState(0);
  const [devMode, setDevmode] = useState(false);

  const developerMode = () => {
    const newClicks = developerClicks + 1;

    console.log(newClicks)
    if (newClicks === 5) {
      setDevmode(true)
    }
    setDeveloperClicks(newClicks);

  }

  return (
    <Grid
      templateAreas={`"header header"
              "nav main"
              "nav footer"`}
      gridTemplateRows={'0px 1fr 30px'}
      gridTemplateColumns={'320px 1fr'}
      h='100vh'
      gap='0'
      fontWeight='bold'
      display={hideNav ? 'block' : 'grid'}
    >
      {!hideNav &&
        <GridItem minW={'320px'} h='100vh' pl='1' padding='0px' area={'nav'} pos={'fixed'} bg={'#D4D4C3'} color={'white'} zIndex={'2'}>
          <Center margin={'30px 0 20px 0'} padding={'0px 0px 0 20px'}>
            <Image onClick={() => developerMode()} width={'100%'} maxHeight={'50px'} src={logo}></Image>
            <ArrowBackIcon color={'black'} position={'absolute'} right='10' onClick={() => setHideNav(true)} />
          </Center>

          <NavButton currentPage={props.page} setPage={props.setPage} page={Page.Overview} path="/" icon={<OverviewLogo />} />
          <NavButton currentPage={props.page} setPage={props.setPage} page={Page.DataSharing} path="/data-sharing" icon={<DataSharingLogo />} />
          <NavButton currentPage={props.page} setPage={props.setPage} page={Page.Marketing} path="/marketing/tailored" icon={<MarketingLogo />} />
          <NavButton currentPage={props.page} setPage={props.setPage} page={Page.Merchants} path="/merchants" icon={<MerchantsLogo />} />
          <NavButton currentPage={props.page} setPage={props.setPage} page={Page.Webhooks} path="/webhooks" icon={<WebhooksLogo />} />

          {devMode && <NavButton currentPage={props.page} setPage={props.setPage} page={Page.Pricing} path="/pricing" icon={<KnowledgeLogo />} />}


          <Stack spacing={'3'} w='100%' position={'absolute'} bottom={'20px'}>

            <Box cursor={'pointer'} padding={'10px 20px 10px 20px'} fontSize={'14px'} onClick={() => window.open(`${config?.documentation.base}${config?.documentation.home}`, '_blank')}>
              <Stack w='50%' direction={'row'} justify={'center'} bg='none' padding={1} borderRadius={'25px'} border={'1px solid #232C2F'}>
                <Text fontFamily={'FoundryPlek'} color={'#232C2F'}>DOCUMENTATION</Text>
              </Stack>
            </Box>

            <Box cursor={'pointer'} padding={'10px 20px 10px 20px'} fontSize={'14px'} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              <Stack w='50%' direction={'row'} justify={'center'} bg='#E7FF7C' padding={1} borderRadius={'25px'} border={'1px solid #232C2F'}>
                <Text fontFamily={'FoundryPlek'} color={'#232C2F'}>SIGN OUT</Text>
              </Stack>
            </Box>
          </Stack>
        </GridItem>
      }

      {hideNav && <ArrowForwardIcon position={'absolute'} left={10} top={10} onClick={() => setHideNav(false)} />}
      <GridItem pl='1' pr='1' area={'main'} bg={'#F4F4EC'} zIndex={'1'}>
        {props.component}
      </GridItem>
      <GridItem pl='1' pr='1' bg={'#F4F4EC'} area={'footer'}>
        <Footer></Footer>
      </GridItem>
    </Grid >
  )
}

const NavButton = (props: { setPage: any, path: string, currentPage: Page, page: Page, minor?: boolean, icon?: any }) => {
  const isSelected = props.currentPage === props.page;
  const navigate = useNavigate();
  return (
    <Box cursor={'pointer'} padding={'10px'} fontWeight={'500'} fontSize={props.minor ? '14px' : '16px'} onClick={() => { props.setPage(props.page); navigate(props.path) }}>
      <Stack direction={'row'} bg={isSelected ? '#c6c7b2' : ''} padding={'10px 0px 10px 20px'} borderRadius={'15px'} >
        <Box style={{ color: isSelected ? '#000000' : '#6C737F' }}>
          {props.icon}
        </Box>
        {props.minor && <ChevronRightIcon />}
        <Text fontFamily={'FoundryPlek'} marginTop={'-2px'} color={isSelected ? '#000000' : '#232C2F'} >{props.page.toUpperCase()}</Text>
      </Stack >
    </Box >)
}

const Home = (props: { setPage: (page: Page) => void }) => {

  const navigate = useNavigate();
  const config = useConfig();;
  return (
    <Container paddingTop='20px' maxW={'8xl'}>
      <Box h='100%' padding={'30px'}>
        <Box >
          <Stack direction='row'>
            <Image
              boxSize='40px'
              objectFit='cover'
              src={wave}
            />
            <Heading fontFamily={'LiberisDisplay'} color='#black' size={'lg'}> Welcome to the Liberis Sandbox dashboard</Heading>
          </Stack>
          <br></br>
          <Heading fontFamily={'LiberisDisplay'} color='#black' size={'md'}> Create merchants, market to them with dashboard adverts, fund them and manage their active experience.</Heading>
          <br></br>
          <Text>In this sandbox you'll be able to test our end-to-end integration with Liberis Create, create and fund test merchants directly from your APIs and services.</Text>
          <br></br>
          <Text>The aim of this environment is to guide developers through a step by step integration with Liberis. This interface allows you to view the internals of your requests and entities created, however the progression of applications are entirely dependent on <b>interacting with our API</b>. You cannot fund a test merchant directly from this interface.</Text>
          <br></br>
          <Divider />
          <Stack spacing={'6'}>
            <br></br>
            <Heading fontFamily={'FoundryPlek'} size={'md'}>GETTING STARTED</Heading>
            <Text>This is where it all begins! Getting moving with your integration is incredibly simple, just follow these steps to begin:</Text>
            <OrderedList fontWeight={'light'}>
              <ListItem><Button colorScheme="blue" variant={'link'} onClick={() => { downloadCollection(config.postmanCollection) }}>Download our Postman collection. </Button> This collection comes preloaded with test credentials, endpoints and local variables to speed up your testing process. </ListItem>
              <ListItem>
                <Button colorScheme="blue" variant={'link'} onClick={() => { navigate('/merchants'); }}>Create a merchant manually
                </Button> or use <Button colorScheme="blue" variant={'link'} onClick={() => { navigate('/data-sharing'); }}> our file ingestion tool</Button> to create a bulk list of test merchants.
              </ListItem>
              <ListItem>
                <Button colorScheme="blue" variant={'link'} onClick={() => { navigate('/knowledge'); }}>Visit our knowledge base</Button> to discover guides and lessons on how to use the Liberis suite of APIs and get your merchants funded as fast as possible.
              </ListItem>
            </OrderedList>
            <Heading fontFamily={'FoundryPlek'} size={'md'}>DOCUMENTATION</Heading>

            <Text>In this sandbox you'll be able to test our end-to-end integration with Liberis Create, however if you need extra supporting documentation on our processes look no further:</Text>
            <UnorderedList fontWeight={'light'}>
              <ListItem><Text>
                <Button colorScheme="blue" cursor={'pointer'} size={'md'} variant={'link'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/sharing-merchant-data', '_blank')}>Data sharing<ExternalLinkIcon /></Button>
                : Learn about how to share merchant data with us upfront, allowing Liberis to check merchant eligbility and return a set of preapproved offers</Text>
              </ListItem>
              <ListItem><Text>
                <Button colorScheme="blue" cursor={'pointer'} size={'md'} variant={'link'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/how-to-guide', '_blank')}>Getting merchant offers<ExternalLinkIcon /></Button>
                : Learn about how to use Liberis Create to get offers for a merchant, allow them to accept an offer and progress an application.</Text>
              </ListItem>
              <ListItem>
                <Text>
                  <Button colorScheme="blue" cursor={'pointer'} size={'md'} variant={'link'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/receiving-webhooks', '_blank')}>Receiving webhook events<ExternalLinkIcon /></Button>
                  : Explore the hooks Liberis send to partners as applications progress and fund.</Text>
              </ListItem>
              <ListItem><Text><Button colorScheme="blue" cursor={'pointer'} size={'md'} variant={'link'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/partner-e-splits-mechanism', '_blank')}>Payment methods<ExternalLinkIcon /></Button>
                : Learn about how Liberis gathers payments from merchants and what you need to do as a partner.</Text>
              </ListItem>
            </UnorderedList>

            <Heading fontFamily={'FoundryPlek'} size={'md'}>API REFERENCE</Heading>
            <Text>You can find our endpoints reference <Button colorScheme="blue" cursor={'pointer'} size={'md'} variant={'link'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/liberis-api-v2-1', '_blank')}>here <ExternalLinkIcon /></Button>
            </Text>
          </Stack>
        </Box>
      </Box>
    </Container >)
}



const Merchants = (props: { setPage: (page: Page) => void, setMerchant: (merchant: Merchant) => void, userId?: string, connectionLost: any }) => {

  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalOpen, setModal] = useState<boolean>(false);
  const [dataModalOpen, setDataModal] = useState<boolean>(false);
  const config = useConfig()

  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>()
  const [time, setTime] = useState(0);

  useEffect(() => {
    let merchantLoop: any;

    const getMerchants = async () => {
      try {
        const response = await axios.get(`${config.api.base}${config.api.control.merchants}?partner=${props.userId}`)
        setMerchants(response.data);
        setSelectedMerchant(response.data.filter((x: Merchant) => x.merchantId === selectedMerchant?.merchantId)[0])

        setTimeout(() => {
          setLoading(false);
        }, 2000);

      } catch (e) {
        props.connectionLost();
      }
    }

    merchantLoop = setTimeout(() => {
      setTime(time + 1);
      getMerchants();
    }, 2000);

    return () => {
      clearTimeout(merchantLoop);
    }
  }, [time, selectedMerchant])



  const { colorMode } = useColorMode()
  const navigate = useNavigate();

  return <Container paddingTop='20px' maxW={'8xl'}>
    <Box h='100%' padding={'30px'}>
      <Stack spacing={'50px'}>
        <Stack spacing={'10px'}>
          <Stack direction={'row'} pos={'relative'}>
            <Heading fontFamily={'LiberisDisplay'} as='h4' size='lg'>Merchant Testing</Heading>
            <Box pos={'absolute'} right={'0'}>
              <Button fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => setModal(true)}><Text color={'#232C2F'}>CREATE MERCHANT</Text></Button>
            </Box>
          </Stack>
          <Text>Create a merchant to get started and begin testing against your API’s with the key stages of the journey.</Text>
        </Stack>

        <Card padding={'0px'} borderRadius={'12px'} boxShadow={'0px 5px 22px 0px #0000000A'}>
          <Heading fontFamily={'FoundryPlek'} margin={'20px 20px 0 20px'} as='h4' size='md'>MERCHANTS</Heading>
          <Text fontSize={'14px'} margin={'0 20px 10px 20px'} color={'#6C737F'}>Create or select a merchant to test the Liberis end to end flow</Text>
          <TableContainer minH={'60px'} borderBottomRadius={'20px'} boxShadow={'0px 0px 0px 0.5px #00000009'} marginTop={'0px'}>
            <Table variant='simple' size={'md'}>
              <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                <Tr>
                  <Th>Merchant Id</Th>
                  <Th>Product</Th>
                  <Th isNumeric>Offer amount</Th>
                  <Th>Decision</Th>
                  <Th>Webhook configured</Th>
                  <Th>Created</Th>
                </Tr>
              </Thead>
              <Tbody>
                {merchants?.map(x => {
                  return <Tr key={x.merchantId} _hover={{ background: "#E7FF7C17" }} cursor={'pointer'} onClick={() => {
                    props.setMerchant(merchants.filter((y: Merchant) => y.merchantId === x.merchantId)[0]); window.scrollTo(0, 0);
                    ; navigate(`/${x.product === ProductType.Flexi ? 'flexi-merchant' : 'merchant'}?merchantId=${x.merchantId}`);
                  }}>
                    <Td>{x.merchantId}</Td>
                    <Td>{x.product === ProductType.Flexi ? ProductType.Flexi : ProductType.BCA}</Td>
                    <Td isNumeric>{formatCurrency(x.currency, x.limits.maximum)}</Td>
                    <Td>{x.decision}</Td>
                    <Td>{x.webhookUrl ? <Tag size={'sm'} variant='subtle'>
                      <TagLeftIcon boxSize='12px' as={CheckIcon} />
                      <TagLabel>Configured</TagLabel>
                    </Tag> :
                      <Tag size={'sm'} variant='subtle'>
                        <TagLeftIcon boxSize='12px' as={CloseIcon} />
                        <TagLabel>Not configured</TagLabel>
                      </Tag>}</Td>
                    <Td>{x.created?.toString().split('T')[0]}</Td>
                    <Divider />
                  </Tr>
                })}
              </Tbody>
            </Table>
          </TableContainer>

          <CreateMerchantModal modalOpen={modalOpen} setModal={setModal} userId={props.userId} />
          {merchants.length === 0 && loading &&
            <Center><Spinner margin={'20px'}
              thickness='2px'
              speed='0.65s'
              emptyColor='gray.200'
              color='#B3B3AA'
              size='lg'
            />
            </Center>
          }
          {(merchants.length === 0 && !loading) &&
            <Center p={7}>
              <Text>Create a merchant to start testing</Text>
            </Center>
          }
        </Card>
      </Stack>
    </Box>
  </Container >
}



export const CardContainer = (props: { children: any, title: string, subheading: any }) => {
  return (
    <Card padding={'0px'} borderRadius={'6px'} boxShadow={'0px 5px 22px 0px #0000000A'}>
      <Heading fontFamily={'FoundryPlek'} margin={'20px 20px 0 20px'} as='h4' size='md'>{props.title}</Heading>
      <Text fontSize={'14px'} margin={'0 20px 10px 20px'} color={'#6C737F'}>{props.subheading}</Text>
      <CardBody padding={0}>
        {props.children}
      </CardBody>
    </Card>
  )
}

const Webhooks = (props: { userId?: string, connectionLost: any }) => {

  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [webhookModal, setWebhookModal] = useState<boolean>(false);
  const [testWebhookModal, setTestWebhookModal] = useState<boolean>(false);
  const [selectedWebhook, setSelectedWebhook] = useState<Webhook>()
  const [merchant, setMerchant] = useState<Merchant>();
  const config = useConfig()


  useEffect(() => {

    const getWebhooks = async () => {
      try {
        const response = await axios.get(`${config.api.base}${config.api.control.webhooks}?partner=${props.userId}`)
        setWebhooks(response.data.reverse());
      }
      catch (e) {
        props.connectionLost();
      }
    }
    let webhooksLoop: any;
    setLoading(true)

    webhooksLoop = setInterval(() => {
      getWebhooks();
      setLoading(false);
    }, 2000);

    return () => {
      clearInterval(webhooksLoop);
    }
  }, [])


  return <Container paddingTop='20px' maxW={'8xl'}>
    <Box h='100%' padding={'30px'}>
      <Stack spacing={'50px'}>
        <Stack spacing={'10px'}>
          <Stack direction={'row'} position={'relative'}>
            <Heading fontFamily={'LiberisDisplay'} as='h4' size='lg'>Webhook Testing</Heading>
            <Box pos={'absolute'} right={'0'}>
              <Button fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => { setTestWebhookModal(true) }}><Text color={'#232C2F'}>SEND TEST WEBHOOK</Text></Button>
            </Box>
          </Stack>
          <Text w='80%'>When a merchant is configured to receive outbound webhooks, you will see the attempts made here. You can also test all outbound hooks depending on the current state of the merchant you wish to test</Text>
        </Stack>

        <Card padding={'0px'} borderRadius={'20px'} boxShadow={'0px 5px 22px 0px #0000000A'}>
          <Heading fontFamily={'FoundryPlek'} margin={'20px 20px 0 20px'} as='h4' size='md'>WEBHOOKS</Heading>
          <Text fontSize={'14px'} margin={'0 20px 10px 20px'} color={'#6C737F'}>Select a webhooks to view more information about the outbound hook attempt</Text>
          <TableContainer boxShadow={'0px 0px 0px 0.5px #00000009'} marginTop={'0px'}>
            <Table variant='simple' size={'md'}>
              <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                <Tr>
                  <Th>Created</Th>
                  <Th>Merchant</Th>
                  <Th>event</Th>
                  <Th>Success</Th>
                  <Th>Response code</Th>
                </Tr>
              </Thead>
              <Tbody>
                {webhooks?.map(x => {
                  return <Tr key={x.id} cursor={'pointer'} onClick={() => { setSelectedWebhook(webhooks.filter((y: Webhook) => y.id === x.id)[0]); setWebhookModal(true) }}>
                    <Td>{x.created.toString()}</Td>
                    <Td>{x.merchantId}</Td>
                    <Td>{x.event}</Td>
                    <Td>{x.success ? <Tag size={'sm'} variant='subtle'>
                      <TagLeftIcon boxSize='12px' as={CheckIcon} />
                      <TagLabel>Success</TagLabel>
                    </Tag> :
                      <Tag size={'sm'} variant='subtle'>
                        <TagLeftIcon boxSize='12px' as={CloseIcon} />
                        <TagLabel>Failed</TagLabel>
                      </Tag>}</Td>
                    <Td>{x.code}</Td>

                  </Tr>
                })}
              </Tbody>
            </Table>
          </TableContainer>

          <ViewWebhook webhookModal={webhookModal} setWebhookModal={setWebhookModal} selectedWebhook={selectedWebhook} />
          {testWebhookModal && <TestWebhookModal canRenew={false} testWebhookModal={testWebhookModal} setTestWebhookModal={setTestWebhookModal} userId={props.userId} selectedMerchant={merchant} connectionLost={props.connectionLost} />}

          {loading &&
            <Center><Spinner margin={'20px'}
              thickness='2px'
              speed='0.65s'
              emptyColor='gray.200'
              color='#B3B3AA'
              size='lg'
            />
            </Center>
          }
        </Card>
      </Stack>
    </Box>
  </Container >

}


export const baseApplication = (currency?: string, merchantId?: string, liberisId?: string, isRenewal?: boolean, amountRequested?: number, offerPresets?: boolean) => {

  return isRenewal ? {
    "application": {
      "merchant_id": merchantId,
      "liberis_id": liberisId,
      "intended_use_of_funds": "Purchase Stock",
      "apply_offer_presets": offerPresets ? offerPresets : false,
      "currency": currency,
      ...amountRequested ? { "amount_requested": amountRequested } : ''
    }
  }
    :
    {
      "application": {
        "merchant_id": merchantId,
        "intended_use_of_funds": "Purchase Stock",
        "currency": currency,
        ...amountRequested ? { "amount_requested": amountRequested } : '',
        "apply_offer_presets": offerPresets
      },
      "consents": {
        "bureau_search": true,
        "application_comms": true
      },
      "applicants": [
        {
          "first_name": "John",
          "last_name": "Doe",
          "email_address": "john.doe@liberis.co.uk",
          "telephone_number": "078123923923",
          "date_of_birth": "1963-10-27",
          "ownership_percentage": 100,
          "primary": true,
          "residences": [
            {
              "address": {
                "line1": "72 Wellington Drive",
                "line2": "Dunston",
                "town_city": "Gateshead",
                "postcode": "NE11 9HE",
                "country": "GBR"
              },
              "moved_in_date": "2020-10-27",
              "residential_status": "Home Owner"
            }
          ]
        }
      ],
      "company": {
        "business_type": "Limited Company",
        "partner_start_date": "2020-04-21",
        "registration_number": "355600",
        "business_start_date": "2012-10-27",
        "industry": "General Store",
        "other_revenue_source": false,
        "legal_name": "Doe Stores",
        "trading_addresses": [
          {
            "trading_name": "Doe Stores",
            "currency": "GBP",
            "address": {
              "line1": "72 Wellington Drive",
              "line2": "Dunston",
              "town_city": "Gateshead",
              "postcode": "NE11 9HE",
              "country": "GBR"
            }
          }
        ],
        "registered_address": {
          "line1": "72 Wellington Drive",
          "line2": "Dunston",
          "town_city": "Gateshead",
          "postcode": "NE11 9HE",
          "country": "GBR"
        },
        "payment_channels": [
          {
            "name": "string",
            "reference": "string",
            "type": "Card Processor",
            "primary": true,
            "existing_split": false,
            "monthly_volumes": [
              {
                "date": "2023-06",
                "amount": 12502.17,
                "count": 5220,
                "currency": "GBP"
              },
              {
                "date": "2023-05",
                "amount": 17122.98,
                "count": 6232,
                "currency": "GBP"
              },
              {
                "date": "2023-04",
                "amount": 9129.65,
                "count": 5232,
                "currency": "GBP"
              },
              {
                "date": "2023-03",
                "amount": 24122.24,
                "count": 7187,
                "currency": "GBP"
              },
              {
                "date": "2023-02",
                "amount": 11182.45,
                "count": 4232,
                "currency": "GBP"
              },
              {
                "date": "2023-01",
                "amount": 9755.94,
                "count": 3886,
                "currency": "GBP"
              },
              {
                "date": "2022-12",
                "amount": 6181.1,
                "count": 3466,
                "currency": "GBP"
              },
              {
                "date": "2022-11",
                "amount": 15875.64,
                "count": 5986,
                "currency": "GBP"
              },
              {
                "date": "2022-10",
                "amount": 27853.23,
                "count": 1132,
                "currency": "GBP"
              },
              {
                "date": "2022-09",
                "amount": 4288.01,
                "count": 1232,
                "currency": "GBP"
              },
              {
                "date": "2022-08",
                "amount": 5534.45,
                "count": 1223,
                "currency": "GBP"
              },
              {
                "date": "2022-07",
                "amount": 9642.34,
                "count": 6232,
                "currency": "GBP"
              }
            ]
          }
        ]
      }
    }
}

export const baseApplicationFromPrepopulation = (prepopData?: PrepopulationData, amountRequested?: number) => {
  if (!prepopData) return;
  return {
    "application": {
      "merchant_id": prepopData.application.merchant_id,
      "intended_use_of_funds": "Purchase Stock",
      "currency": prepopData.company.currency,
      ...amountRequested ? { "amount_requested": amountRequested } : '',
      "apply_offer_presets": true
    },
    "consents": {
      "bureau_search": true,
      "application_comms": true
    },
    "applicants": prepopData.applicants.map((applicant => {
      return {
        "first_name": applicant.first_name,
        "last_name": applicant.last_name,
        "email_address": applicant.email_address,
        "telephone_number": applicant.telephone_number,
        "date_of_birth": applicant.date_of_birth,
        "ownership_percentage": applicant.ownership_percentage,
        "primary": applicant.primary,
        "residences": [
          {
            "address": {
              "line1": applicant.residences.address.line1,
              "line2": applicant.residences.address.line2,
              "town_city": applicant.residences.address.town_city,
              "postcode": applicant.residences.address.postcode,
              "country": applicant.residences.address.country,
            },
            "residential_status": applicant.residences.residential_status
          }
        ]
      }
    }))
    ,
    "company": {
      "business_type": prepopData.company.business_type,
      "partner_start_date": prepopData.company.partner_start_date,
      "registration_number": prepopData.company.registration_number,
      "business_start_date": prepopData.company.business_start_date,
      "industry": prepopData.company.industry,
      "legal_name": prepopData.company.legal_name,
      "trading_addresses": prepopData.company.trading_addresses.map((address) => {
        return {
          "trading_name": address.trading_name,
          "currency": prepopData.company.currency,
          "address": {
            "line1": address.line1,
            "line2": address.line2,
            "town_city": address.town_city,
            "postcode": address.postcode,
            "country": address.country
          }
        }
      })
      ,
      "registered_address": {
        "line1": prepopData.company.registered_address.line1,
        "line2": prepopData.company.registered_address.line2,
        "town_city": prepopData.company.registered_address.town_city,
        "postcode": prepopData.company.registered_address.postcode,
        "country": prepopData.company.registered_address.country
      },
    }
  }
}

export const baseFlexiApplication = (partner?: string, merchantId?: string, liberisId?: string, isRenewal?: boolean, amountRequested?: number, offerPresets?: boolean) => {

  return {
    "referringPartnerCode": partner,
    "applicants": [
      {
        "identityDocuments": [
          {
            "type": "SocialSecurityNumber",
            "countryOfIssue": "USA",
            "number": "123456789"
          }
        ],
        "firstName": "John",
        "lastName": "Doe",
        "residences": [
          {
            "address": {
              "lineOne": "123 Main St",
              "lineTwo": "Apt 1",
              "city": "New York",
              "postCode": "10001",
              "state": "NY",
              "country": "USA"
            },
            "movedInDate": "2010-01-01",
            "movedOutDate": "2020-01-01"
          }
        ],
        "email": "example@email.com",
        "phoneNumber": "+1-418-543-8090",
        "dateOfBirth": "1980-01-01",
        "ownershipPercentage": 50,
        "isPrimaryApplicant": true
      }
    ],
    "company": {
      "referringPartnerExternalId": merchantId,
      "otherPartnerExternalId": [
        {
          "code": partner,
          "externalId": merchantId
        }
      ],
      "businessType": "Corporation",
      "phoneNumber": "+1-418-543-8090",
      "email": "flexi_email@company.com",
      "registrationNumber": "704181691",
      "registeredName": "Flexi Company Name",
      "tradingAddress": {
        "lineOne": "123 Main St",
        "lineTwo": "Apt 1",
        "city": "New York",
        "postCode": "10001",
        "state": "NY",
        "country": "USA"
      },
      "registeredAddress": {
        "lineOne": "123 Main St",
        "lineTwo": "Apt 1",
        "city": "New York",
        "postCode": "10001",
        "state": "NY",
        "country": "USA"
      },
      "tradingName": "Flexi Company Name",
      "sector": "FashionRetail"
    },
    "consent": {
      "isCreditSearch": true,
      "isProductCommsAndMarketing": true,
      "isRenewalsCommsAndMarketing": true
    },
    "intendedUseOfFunds": "Working Capital",
    "currency": "USD",
    "bankDetails": {
      "bankName": "Flexi Bank",
      "routingNumber": "USBKUS44I",
      "bankAccountNumber": "123456789"
    }
  }
}

export const baseFlexiAdvance = (amount: number, account?: FlexiAccount) => {

  const fee = Number(((amount * 1.12) - amount).toFixed(2));
  const repayment = Number((amount * 1.12).toFixed(2));

  if (account) {
    return {
      "flexiAccountId": account.id,
      "accountSize": account.limits.size,
      "amount": amount,
      "currency": account.application.currency,
      "fee": fee,
      "factorRate": 1.12,
      "beforeBalance": {
        "outstandingBalance": 0,
        "availableAccountSize": account.limits.size
      },
      "afterBalance": {
        "outstandingBalance": account.outstandingBalance + repayment,
        "availableAccountSize": account.limits.size - repayment,
      },
      "feeBreakdown": [
        {
          "factorRate": 1.12,
          "advancePortion": 0,
          "fee": fee
        }
      ],
      "contracts": [
        {
          "ipAddress": "120.117.158.105",
          "templateVersion": "flexi/advance_standard_terms/0.1",
          "consent": true
        }
      ]
    }
  }
}

const basePayment = (date: string, splitPercent?: number, transactionAmount?: number, transactionType?: string, merchantId?: string, liberisId?: string, currency?: string) => {
  if (splitPercent && transactionAmount && transactionType) {
    return {
      "repayments": [
        {
          "batchDate": date,
          "batchNumber": "ABC123",
          "companyId": merchantId,
          "liberisId": liberisId,
          "currencyCode": currency,
          "transactionType": transactionType,
          "transactionAmount": transactionAmount,
          "repaymentAmount": Number((transactionAmount / 100 * splitPercent).toFixed(2)),
          "settlementStart": date,
          "settlementEnd": date
        }
      ]
    }
  }
}

export const formatCurrency = (currency?: string, amount?: number | string) => {
  switch (currency) {
    case 'USD':
      return `$${amount}`
    case 'EUR':
      return `€${amount}`
    case 'GBP':
      return `£${amount}`
    case 'CAD':
      return `$${amount}`
    case 'ISK':
      return `ISK ${amount}`
    case 'SEK':
      return `SEK ${amount}`
    case 'CZK':
      return `CZK ${amount}`
    case 'DKK':
      return `DKK ${amount}`
  }
}

export const DealStatusModal = (props: { statusModalOpen: boolean, setStatusModal: any, deal?: Deal, connectionLost: any }) => {

  const [status, setStatus] = useState<string>()
  const config = useConfig()

  const changeStatus = async () => {
    try {
      await axios.patch(`${config.api.base}${config.api.control.deal}/status`, { id: props.deal?.id, status })
    }
    catch (e) {
      props.connectionLost();
    }
    return;
  }

  return (<Modal
    isOpen={props.statusModalOpen}
    onClose={() => props.setStatusModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Change deal status for {props.deal?.id}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl mt={4} isRequired>
          <FormLabel>Status</FormLabel>
          <Select onChange={(e) => setStatus(e.target.value)} placeholder='Select status'>
            <option>Pre Decisioned</option>
            <option>Created</option>
            <option>Declined</option>
            <option>Rescinded</option>
            <option>Terminated</option>
            <option>Underperforming</option>
            <option>Written Off</option>
            <option>Contracts Processing</option>
            <option>Payment Setup Review</option>
            <option>Ready for Funding</option>
            <option>Funded</option>
            <option>Repaid</option>
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={() => { changeStatus(); props.setStatusModal(false) }} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'}>SAVE</Button>
        <Button mr={3} fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} onClick={() => props.setStatusModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}


export const AccountStatusModal = (props: { statusModalOpen: boolean, setStatusModal: any, account?: FlexiAccount, connectionLost: any }) => {

  const [status, setStatus] = useState<string>()
  const config = useConfig()

  const changeStatus = async () => {
    try {
      await axios.patch(`${config.api.base}/control/account/status`, { id: props.account?.id, status })
    }
    catch (e) {
      props.connectionLost();
    }
    return;
  }

  return (<Modal
    isOpen={props.statusModalOpen}
    onClose={() => props.setStatusModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Change account status for {props.account?.id}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl mt={4} isRequired>
          <FormLabel>Status</FormLabel>
          <Select onChange={(e) => setStatus(e.target.value)} placeholder='Select status'>
            <option>Active</option>
            <option>Frozen</option>
            <option>EndOfAvailabilityPeriod</option>
            <option>Paid</option>
            <option>WrittenOff</option>
            <option>NeverUtilised</option>
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={() => { changeStatus(); props.setStatusModal(false) }} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'}>SAVE</Button>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setStatusModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}


export const DeleteMerchantModal = (props: { deleteModalOpen: boolean, setDeleteModal: any, merchantId?: string }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const config = useConfig()
  const deleteMerchant = async (merchantId?: string) => {
    try {
      await axios.delete(`${config.api.base}${config.api.control.merchants}?merchantId=${merchantId}`)

      toast({
        title: `Deleting merchant ${merchantId}`,
        description: "Deleting a merchant also deletes all related records.",
        status: 'warning',
        duration: 4000,
        isClosable: true
      })

      navigate('/merchants');
    }
    catch (e) {
      console.log(e);
    }
    return;
  }

  return (<Modal
    isOpen={props.deleteModalOpen}
    onClose={() => props.setDeleteModal(false)}
    motionPreset='slideInBottom'
    size='lg'

  >
    <ModalOverlay />
    <ModalContent p='5px'>
      <ModalHeader><Image w='44px' src={cross}></Image></ModalHeader>
      <ModalCloseButton />
      <Stack spacing={5}>
        <ModalBody>
          <Heading size={'md'}>Are you sure?</Heading>
          <Text color={'#6C737F'}>Once you delete a merchant, there is no way to undo this.</Text>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button mr={3} onClick={() => { deleteMerchant(props.merchantId) }} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} >Delete</Button>
        </ModalFooter>
      </Stack>
    </ModalContent>
  </Modal>)
}


export const ViewDealModal = (props: { dataModalOpen: boolean, setDataModal: any, deal?: Deal }) => {
  const { colorMode } = useColorMode()
  return (<Modal
    isOpen={props.dataModalOpen}
    onClose={() => props.setDataModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View deal</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <JsonViewer theme={colorMode as JsonViewerTheme} value={props.deal} />
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setDataModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const ViewAccountModal = (props: { dataModalOpen: boolean, setDataModal: any, account?: FlexiAccount }) => {
  const { colorMode } = useColorMode()
  return (<Modal
    isOpen={props.dataModalOpen}
    onClose={() => props.setDataModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View account</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <JsonViewer theme={colorMode as JsonViewerTheme} value={props.account} />
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setDataModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal>)
}


export const PaymentSetupModal = (props: { paymentSetupModalOpen: boolean, setPaymentSetupModal: any, deal?: Deal, merchant?: Merchant, completedStep?: () => void }) => {

  const [merchant, setMerchant] = useState<Merchant>()
  const [time, setTime] = useState<number>(0);
  const [deal, setDeal] = useState<Deal>();

  const context = useContext(AppContext);

  useEffect(() => {
    setDeal(props.deal);
    setMerchant(props.merchant);
  }, [props.merchant, props.deal])

  const config = useConfig()


  const onClose = () => {
    if (deal?.status === 'Funded' && props.completedStep) { props.completedStep() }
    props.setPaymentSetupModal(false)
  }

  return (<Modal
    isOpen={props.paymentSetupModalOpen}
    onClose={onClose}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <Button cursor={'pointer'} size={'sm'} variant={'link'} position={'absolute'} right={'80px'} top={'25px'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/replying-to-webhook-actions', '_blank')}>Setting up Payments <ExternalLinkIcon /></Button>
      <ModalHeader>Setup payment method</ModalHeader>
      <ModalCloseButton />
      <Box padding={'15px'}><Text>Once a deal enters the status 'Payment Setup Review', a webhook message will be sent to your server, dependent on your agreed payment mechanism. You will then action the payment setup, setting up a split or routing a merchants settlement account. The webhook contains an <Code>actions.notify</Code> field that you will then use to call the <Code>{config.api.base}/actions</Code> endpoint, which will progress the application to 'Funded' and allow the submission of payments. </Text></Box>

      <ModalBody>
        <SimpleGrid columns={1} spacing={5} margin={'10px'}>
          <Text>In order to confirm payment setup for this deal, make a request to the following:</Text>
          {deal?.status === 'Funded' && <Alert status='success'>
            <AlertIcon />
            Payment setup received, you can now submit payments!
          </Alert>}
          <Code padding={'20px'}>
            <CopyButton text={`${config.api.base}/actions/funding/asv-notify/${deal?.id}`} />
            <b>POST</b> {config.api.base}/actions/funding/asv-notify/{deal?.id}
          </Code>

        </SimpleGrid>

      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={onClose}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal >)
}


export const FlexiPaymentSetupModal = (props: { paymentSetupModalOpen: boolean, setPaymentSetupModal: any, account?: FlexiAccount, merchant?: Merchant, completedStep?: () => void }) => {

  const [merchant, setMerchant] = useState<Merchant>()
  const [account, setAccount] = useState<FlexiAccount>();

  const context = useContext(AppContext);

  useEffect(() => {
    setAccount(props.account);
    setMerchant(props.merchant);
  }, [props.merchant, props.account])

  const config = useConfig()


  const onClose = () => {
    if (account?.status === 'Funded' && props.completedStep) { props.completedStep() }
    props.setPaymentSetupModal(false)
  }

  return (<Modal
    isOpen={props.paymentSetupModalOpen}
    onClose={onClose}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <Button cursor={'pointer'} size={'sm'} variant={'link'} position={'absolute'} right={'80px'} top={'25px'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/replying-to-webhook-actions', '_blank')}>Setting up Payments <ExternalLinkIcon /></Button>
      <ModalHeader>Setup payment method</ModalHeader>
      <ModalCloseButton />
      <Box padding={'15px'}><Text>Once an account enters the status 'Payment Setup Review', a webhook message will be sent to your server, dependent on your agreed payment mechanism. You will then action the payment setup, setting up a split or routing a merchants settlement account. The webhook contains an <Code>actions.notify</Code> field that you will then use to call the <Code>{config.api.base}/actions</Code> endpoint, which will progress the application to 'Funded' and allow the submission of payments. </Text></Box>

      <ModalBody>
        <SimpleGrid columns={1} spacing={5} margin={'10px'}>
          <Text>In order to confirm payment setup for this deal, make a request to the following:</Text>
          {account?.status === 'Funded' && <Alert status='success'>
            <AlertIcon />
            Payment setup received, you can now submit payments!
          </Alert>}
          <Code padding={'20px'}>
            <CopyButton text={`${config.api.base}/actions/funding/asv-notify/${account?.id}`} />
            <b>POST</b> {config.api.base}/actions/funding/asv-notify/{account?.id}
          </Code>

        </SimpleGrid>

      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={onClose}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal >)
}

export const CreatePaymentModal = (props: { paymentModalOpen: boolean, setPaymentModal: any, deal?: Deal, userId?: string, connectionLost: any }) => {

  const [paymentMethod, setPaymentMethod] = useState<string>(PaymentMethod.PartnerCreated)
  const [paymentType, setPaymentType] = useState<string>('Settled')
  const [transactionAmount, setTransactionAmount] = useState<number>(20000)
  const [repaymentAmount, setRepaidAmount] = useState<number>(Number((20000 / 100 * (props.deal ? props.deal?.pricing?.split_percent : 10)).toFixed(2)))
  const [date, setDate] = useState<string>(new Date().toISOString())
  const [isLoading, setLoading] = useState<boolean>();


  const toast = useToast();

  const createPayment = async () => {
    try {
      setLoading(true)
      await axios.post(`${config.api.base}${config.api.control.payment}`, { repaymentAmount, transactionAmount, transactionDate: date, transactionType: paymentType, partner: props.userId, dealId: props.deal?.id });

      toast({
        title: 'Payment successfully created',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setLoading(false)
    }
    catch (e) {
      props.connectionLost();
      setLoading(false);
    }
  }

  const { colorMode } = useColorMode()
  const config = useConfig()

  return (<Modal
    isOpen={props.paymentModalOpen}
    onClose={() => props.setPaymentModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <Button cursor={'pointer'} size={'sm'} variant={'link'} position={'absolute'} right={'80px'} top={'25px'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/the-settlement-api', '_blank')}>Adding payments <ExternalLinkIcon /></Button>
      <ModalHeader>Add a payment </ModalHeader>
      <ModalCloseButton />

      {paymentMethod === PaymentMethod.PartnerCreated &&
        <Box padding={'15px'}><Text>POST the below payload to <Code>{config.api.base}/finance/v1/settlements</Code> to create a payment against the selected deal.</Text></Box>
      }
      {paymentMethod === PaymentMethod.LiberisCreated &&
        <Box padding={'15px'}><Text>Use the interface below to simulate payments being added to a deal by Liberis processes. This is for partners that do not use the settlement API to report payments, ie Settlement account partners, Direct Debit or file based reconciliations.</Text></Box>
      }
      <ModalBody>

        {props.deal?.transactions?.filter(x => new Date(x.transaction_date)?.toISOString() === date) && props.deal?.transactions?.filter(x => new Date(x.transaction_date)?.toISOString() === date)?.length > 0 && <Alert status='success' marginBottom={'20px'}>
          <AlertIcon />
          Payment has been received!
        </Alert>}

        <FormControl>
          <FormLabel>Payment Type</FormLabel>
          <Select defaultValue={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} placeholder='Select payment method'>
            <option>Partner split payment</option>
            <option>Liberis created payment</option>
          </Select>
        </FormControl>

        {paymentMethod === PaymentMethod.LiberisCreated &&
          <Box>
            <SimpleGrid columns={2} spacing={5} margin={'10px'}>
              <Stat>
                <StatLabel>Split percentage</StatLabel>
                <StatNumber>{props.deal?.pricing.split_percent}%</StatNumber>
              </Stat>


              <Stat>
                <StatLabel>Currency</StatLabel>
                <StatNumber>{props.deal?.currency}</StatNumber>
              </Stat>

              <FormControl isRequired>
                <FormLabel>Payment Type</FormLabel>
                <Select defaultValue={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder='Select transaction type'>
                  <option>Unsettled</option>
                  <option>Settled</option>
                  <option>Collection</option>
                  <option>Refund</option>
                  <option>Non-Split Collection</option>
                  <option>Payoff</option>

                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Revenue amount</FormLabel>
                <Input defaultValue={transactionAmount} onChange={(e) => setTransactionAmount(Number(e.target.value))} placeholder='' />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Repaid Amount</FormLabel>
                <Input defaultValue={repaymentAmount} onChange={(e) => setRepaidAmount(Number(e.target.value))} placeholder='' />
              </FormControl>

              <FormControl cursor={'pointer'} isRequired>
                <FormLabel>Payment date</FormLabel>
                <DatePicker className="pointer" selected={new Date(date)} onChange={(d: Date) => setDate(d?.toISOString())} />
              </FormControl>

            </SimpleGrid>
            {paymentType === 'Unsettled' ? <Alert fontSize={'12px'} status='warning'>
              <AlertIcon />
              This payment will NOT roll down the balance of the deal.
            </Alert> :
              paymentType === 'Refund' ? <Alert fontSize={'12px'} status='warning'>
                <AlertIcon />
                This payment will add the repaid value to the total balance of the deal.
              </Alert> : <Alert fontSize={'12px'} status='success'>
                <AlertIcon />
                This payment will roll down the balance of the deal.
              </Alert>
            }
          </Box>}


        {paymentMethod === PaymentMethod.PartnerCreated && <Box>
          <SimpleGrid columns={2} spacing={5} margin={'10px'}>
            <Stat>
              <StatLabel>Split percentage</StatLabel>
              <StatNumber>{props.deal?.pricing.split_percent}%</StatNumber>
            </Stat>


            <Stat>
              <StatLabel>Currency</StatLabel>
              <StatNumber>{props.deal?.currency}</StatNumber>
            </Stat>

            <FormControl isRequired>
              <FormLabel>Payment Type</FormLabel>
              <Select defaultValue={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder='Select transaction type'>
                <option>Unsettled</option>
                <option>Settled</option>
                <option>Revenue</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Revenue amount</FormLabel>
              <Input defaultValue={transactionAmount} onChange={(e) => setTransactionAmount(Number(e.target.value))} placeholder='' />
            </FormControl>

          </SimpleGrid>
          {paymentType === 'Settled' ? <Alert fontSize={'12px'} status='success'>
            <AlertIcon />
            This payment will roll down the balance of the deal.
          </Alert> : <Alert fontSize={'12px'} status='warning'>
            <AlertIcon />
            This payment will NOT roll down the balance of the deal.
          </Alert>}
          <Divider marginTop={'15px'} />
          <JsonViewer theme={colorMode as JsonViewerTheme} value={basePayment(date, props.deal?.pricing.split_percent, transactionAmount, paymentType, props.deal?.merchantId, props.deal?.liberis_id, props.deal?.currency)} />
        </Box>}


      </ModalBody>
      <ModalFooter>
        {paymentMethod === 'Liberis created payment' && paymentType && transactionAmount && repaymentAmount && date && <Button isLoading={isLoading} mr={3} onClick={() => { createPayment() }} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'150px'}>Create payment</Button>}
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setPaymentModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal >
  )
}


export const FlexiCreatePaymentModal = (props: { paymentModalOpen: boolean, setPaymentModal: any, account?: FlexiAccount, userId?: string, connectionLost: any }) => {

  const [paymentMethod, setPaymentMethod] = useState<string>(PaymentMethod.PartnerCreated)
  const [paymentType, setPaymentType] = useState<string>('Settled')
  const [transactionAmount, setTransactionAmount] = useState<number>(20000)
  const [repaymentAmount, setRepaidAmount] = useState<number>(Number((20000 / 100 * (props.account ? props.account?.split : 10)).toFixed(2)))
  const [date, setDate] = useState<string>(new Date().toISOString())
  const [isLoading, setLoading] = useState<boolean>();


  const toast = useToast();

  const createPayment = async () => {
    try {
      setLoading(true)
      await axios.post(`${config.api.base}${config.api.control.payment}`, { repaymentAmount, transactionAmount, transactionDate: date, transactionType: paymentType, partner: props.userId, dealId: props.account?.id });

      toast({
        title: 'Payment successfully created',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setLoading(false)
    }
    catch (e) {
      props.connectionLost();
      setLoading(false);
    }
  }

  const { colorMode } = useColorMode()
  const config = useConfig()

  return (<Modal
    isOpen={props.paymentModalOpen}
    onClose={() => props.setPaymentModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <Button cursor={'pointer'} size={'sm'} variant={'link'} position={'absolute'} right={'80px'} top={'25px'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/the-settlement-api', '_blank')}>Adding payments <ExternalLinkIcon /></Button>
      <ModalHeader>Add a payment </ModalHeader>
      <ModalCloseButton />

      {paymentMethod === PaymentMethod.PartnerCreated &&
        <Box padding={'15px'}><Text>POST the below payload to <Code>{config.api.base}/finance/v1/settlements</Code> to create a payment against the selected deal.</Text></Box>
      }
      {paymentMethod === PaymentMethod.LiberisCreated &&
        <Box padding={'15px'}><Text>Use the interface below to simulate payments being added to a deal by Liberis processes. This is for partners that do not use the settlement API to report payments, ie Settlement account partners, Direct Debit or file based reconciliations.</Text></Box>
      }
      <ModalBody>

        {props.account?.transactions?.filter(x => new Date(x.transaction_date)?.toISOString() === date) && props.account?.transactions?.filter(x => new Date(x.transaction_date)?.toISOString() === date)?.length > 0 && <Alert status='success' marginBottom={'20px'}>
          <AlertIcon />
          Payment has been received!
        </Alert>}

        <FormControl>
          <FormLabel>Payment Type</FormLabel>
          <Select defaultValue={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} placeholder='Select payment method'>
            <option>Partner split payment</option>
            <option>Liberis created payment</option>
          </Select>
        </FormControl>

        {paymentMethod === PaymentMethod.LiberisCreated &&
          <Box>
            <SimpleGrid columns={2} spacing={5} margin={'10px'}>
              <Stat>
                <StatLabel>Split percentage</StatLabel>
                <StatNumber>{props.account?.split}%</StatNumber>
              </Stat>


              <Stat>
                <StatLabel>Currency</StatLabel>
                <StatNumber>{props.account?.application.currency}</StatNumber>
              </Stat>

              <FormControl isRequired>
                <FormLabel>Payment Type</FormLabel>
                <Select defaultValue={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder='Select transaction type'>
                  <option>Unsettled</option>
                  <option>Settled</option>
                  <option>Collection</option>
                  <option>Refund</option>
                  <option>Non-Split Collection</option>
                  <option>Payoff</option>

                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Revenue amount</FormLabel>
                <Input defaultValue={transactionAmount} onChange={(e) => setTransactionAmount(Number(e.target.value))} placeholder='' />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Repaid Amount</FormLabel>
                <Input defaultValue={repaymentAmount} onChange={(e) => setRepaidAmount(Number(e.target.value))} placeholder='' />
              </FormControl>

              <FormControl cursor={'pointer'} isRequired>
                <FormLabel>Payment date</FormLabel>
                <DatePicker className="pointer" selected={new Date(date)} onChange={(d: Date) => setDate(d?.toISOString())} />
              </FormControl>

            </SimpleGrid>
            {paymentType === 'Unsettled' ? <Alert fontSize={'12px'} status='warning'>
              <AlertIcon />
              This payment will NOT roll down the balance of the deal.
            </Alert> :
              paymentType === 'Refund' ? <Alert fontSize={'12px'} status='warning'>
                <AlertIcon />
                This payment will add the repaid value to the total balance of the deal.
              </Alert> : <Alert fontSize={'12px'} status='success'>
                <AlertIcon />
                This payment will roll down the balance of the deal.
              </Alert>
            }
          </Box>}


        {paymentMethod === PaymentMethod.PartnerCreated && <Box>
          <SimpleGrid columns={2} spacing={5} margin={'10px'}>
            <Stat>
              <StatLabel>Split percentage</StatLabel>
              <StatNumber>{props.account?.split}%</StatNumber>
            </Stat>


            <Stat>
              <StatLabel>Currency</StatLabel>
              <StatNumber>{props.account?.application.currency}</StatNumber>
            </Stat>

            <FormControl isRequired>
              <FormLabel>Payment Type</FormLabel>
              <Select defaultValue={paymentType} onChange={(e) => setPaymentType(e.target.value)} placeholder='Select transaction type'>
                <option>Unsettled</option>
                <option>Settled</option>
                <option>Revenue</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Revenue amount</FormLabel>
              <Input defaultValue={transactionAmount} onChange={(e) => setTransactionAmount(Number(e.target.value))} placeholder='' />
            </FormControl>

          </SimpleGrid>
          {paymentType === 'Settled' ? <Alert fontSize={'12px'} status='success'>
            <AlertIcon />
            This payment will roll down the balance of the deal.
          </Alert> : <Alert fontSize={'12px'} status='warning'>
            <AlertIcon />
            This payment will NOT roll down the balance of the deal.
          </Alert>}
          <Divider marginTop={'15px'} />
          <JsonViewer theme={colorMode as JsonViewerTheme} value={basePayment(date, props.account?.split, transactionAmount, paymentType, props.account?.merchantId, props.account?.liberis_id, props.account?.application.currency)} />
        </Box>}


      </ModalBody>
      <ModalFooter>
        {paymentMethod === 'Liberis created payment' && paymentType && transactionAmount && repaymentAmount && date && <Button isLoading={isLoading} mr={3} onClick={() => { createPayment() }} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'150px'}>Create payment</Button>}
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setPaymentModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal >
  )
}

export const ViewPaymentsModal = (props: { viewPaymentModalOpen: boolean, setViewPaymentModal: any, data?: Deal | FlexiAccount, }) => {
  return (<Modal
    isOpen={props.viewPaymentModalOpen}
    onClose={() => props.setViewPaymentModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View payments for {props.data?.merchantId}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <TableContainer>
          <Table size='sm' variant='striped'>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Type</Th>
                <Th>Split</Th>
                <Th>Revenue</Th>
                <Th>Repayment</Th>
              </Tr>
            </Thead>


            <Tbody>
              {props.data?.transactions?.map(x => {
                return <Tr>
                  <Td>{x.transaction_date.toString().split('T')[0]}</Td>
                  <Td>{x.transaction_type}</Td>
                  <Td>{x.repay_rate}%</Td>
                  <Td>{x.transaction_amount}</Td>
                  <Td>{x.repay_amount}</Td>
                </Tr>
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setViewPaymentModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal>)
}


export const ViewPrequalifiedDataModal = (props: { viewPrequalModalOpen: boolean, setViewPrequalModal: any, revenue?: ClaimsData, selectedChannel?: string }) => {
  return (<Modal
    isOpen={props.viewPrequalModalOpen}
    onClose={() => props.setViewPrequalModal(false)}
    motionPreset='slideInBottom'
    size='5xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Revenue for Partner Claim {props.selectedChannel}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack>
          <TableContainer>
            <Table size='sm' variant='striped'>
              <Thead>
                <Tr>
                  <Th>Start Time</Th>
                  <Th>End Time</Th>
                  <Th>Category type</Th>
                  <Th>Category code</Th>
                  <Th>Total amount</Th>
                  <Th>Currency</Th>
                  <Th>Count</Th>
                </Tr>
              </Thead>

              <Tbody>
                {props.revenue?.revenue?.map(x => {
                  return <Tr key={`${x.time_period.start_time}-${x.time_period.start_time}`}>
                    <Td>{x.time_period.start_time.toString()}</Td>
                    <Td>{x.time_period.end_time.toString()}</Td>
                    <Td>{x.industry_category.scheme}</Td>
                    <Td>{x.industry_category.code}</Td>
                    <Td>{x.total_amount}</Td>
                    <Td>{x.currency}</Td>
                    <Td>{x.transaction_count}</Td>
                  </Tr>
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setViewPrequalModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal>)
}


export const GetOffersModal = (props: {
  getOffersModalOpen: boolean,
  setGetOffersModalOpen: any,
  merchantId?: string | null,
  merchant?: Merchant,
  isRenewal?: boolean,
  liberisId?: string,
  completedStep?: () => void,
  amountRequested?: number
}
) => {
  const { colorMode } = useColorMode()
  const [merchant, setMerchant] = useState<Merchant>()
  const [time, setTime] = useState<number>(0);

  const context = useContext(AppContext);
  const config = useConfig()

  useEffect(() => {
    setMerchant(props.merchant)
  }, [props.merchant])

  const onClose = () => { props.setGetOffersModalOpen(false); if (((merchant?.stage === MerchantStage.OffersCreated && !props.amountRequested) || (merchant?.stage === MerchantStage.OffersCustomised && props.amountRequested)) && props.completedStep) props.completedStep(); }

  return (<Modal blockScrollOnMount={true}
    isOpen={props.getOffersModalOpen}
    onClose={onClose}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Example request to create {props.isRenewal ? 'a Renewal' : 'an Initial'} offer</ModalHeader>
      <br></br>
      <Center>
        <Button w={'200px'} cursor={'pointer'} size={'md'} variant={'link'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/liberis-api-v2-1', '_blank')}>API REFERENCE<ExternalLinkIcon /></Button>
      </Center>
      <br></br>
      <ModalCloseButton />
      <Box padding={'15px'}><Text>POST the below payload to <Code>{config.api.base}/create/v2/offers</Code> to create a set of offers.</Text>
      </Box>
      <ModalBody>
        {merchant?.stage === MerchantStage.OffersCreated && !props.amountRequested && <Alert status='success'>
          <AlertIcon />
          Offers successfully created for {merchant.merchantId}.
        </Alert>}
        {merchant?.stage === MerchantStage.OffersCustomised && <Alert status='success'>
          <AlertIcon />
          Offers successfully customised for {merchant.merchantId}.
        </Alert>}
        <Divider margin={'15px 0 15px 0'} />
        {!merchant && <Center><Spinner /></Center>}
        {merchant && <JsonViewer theme={colorMode as JsonViewerTheme} value={baseApplication(merchant?.currency, merchant?.merchantId, props.liberisId, props.isRenewal, props.amountRequested)} />}
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const GetFlexiOffersModal = (props: {
  getOffersModalOpen: boolean,
  setGetOffersModalOpen: any,
  merchantId?: string | null,
  merchant?: Merchant,
  isRenewal?: boolean,
  liberisId?: string,
  completedStep?: () => void,
  amountRequested?: number
}
) => {
  const { colorMode } = useColorMode()
  const [merchant, setMerchant] = useState<Merchant>()
  const [time, setTime] = useState<number>(0);

  const context = useContext(AppContext);
  const config = useConfig()

  useEffect(() => {
    setMerchant(props.merchant)
  }, [props.merchant])

  const onClose = () => { props.setGetOffersModalOpen(false); if (((merchant?.stage === MerchantStage.OffersCreated && !props.amountRequested) || (merchant?.stage === MerchantStage.OffersCustomised && props.amountRequested)) && props.completedStep) props.completedStep(); }

  return (<Modal blockScrollOnMount={true}
    isOpen={props.getOffersModalOpen}
    onClose={onClose}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Example request to create a Flexi application</ModalHeader>
      <br></br>
      <ModalCloseButton />
      <Box padding={'30px'}>
        <Heading as='h5' size='md'>Step 1</Heading>

        <Box><Text> To create an application, you must first gather offers for a Flexi application. Perform a GET request to the following to gather account offers for a specific merchant.</Text> <Code w={'100%'}> <CopyButton text={`${config.api.base}/flexi/api/v1/account-offer?partnerCode=${merchant?.partner}&partnerClientId=${merchant?.merchantId}`} />{config.api.base}/flexi/api/v1/account-offer?partnerCode={merchant?.partner}&partnerClientId={merchant?.merchantId}</Code>
          {(merchant?.stage === MerchantStage.OffersCreated || merchant?.stage === MerchantStage.ContractSigned) && !props.amountRequested && <Alert status='success'>
            <AlertIcon />
            Offers successfully created for {merchant.merchantId}.
          </Alert>}
          <Divider margin={'15px 0 15px 0'} />

        </Box>
      </Box>


      <Box padding={'0 30px 0 30px'}>
        <Heading as='h5' size='md'>Step 2</Heading>
        <Text>POST the below payload to <Code>{config.api.base}/flexi/api/v1/application</Code> to create a Flexi application.</Text>
      </Box>
      <ModalBody padding={'0 30px 0 30px'}>
        {(merchant?.stage === MerchantStage.ContractSigned) && <Alert status='success'>
          <AlertIcon />
          Application successfully created for {merchant.merchantId}.
        </Alert>}
        <Divider margin={'15px 0 15px 0'} />
        {!merchant && <Center><Spinner /></Center>}
        {merchant && <JsonViewer theme={colorMode as JsonViewerTheme} value={baseFlexiApplication(merchant?.partner, merchant?.merchantId, props.liberisId, props.isRenewal, props.amountRequested)} />}
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const GetFlexiAdvanceModal = (props: {
  getAdvanceModalOpen: boolean,
  setGetAdvanceModalOpen: any,
  account?: FlexiAccount,
  merchantId?: string | null,
  merchant?: Merchant,
  isRenewal?: boolean,
  liberisId?: string,
  completedStep?: () => void,
  amountRequested?: number
}
) => {
  const { colorMode } = useColorMode()
  const [merchant, setMerchant] = useState<Merchant>()
  const [time, setTime] = useState<number>(0);

  const [amount, setAmount] = useState<number>(5000);
  const context = useContext(AppContext);
  const config = useConfig()

  useEffect(() => {
    setMerchant(props.merchant)
  }, [props.merchant])

  const onClose = () => { props.setGetAdvanceModalOpen(false); if (((merchant?.stage === MerchantStage.OffersCreated && !props.amountRequested) || (merchant?.stage === MerchantStage.OffersCustomised && props.amountRequested)) && props.completedStep) props.completedStep(); }

  return (<Modal blockScrollOnMount={true}
    isOpen={props.getAdvanceModalOpen}
    onClose={onClose}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Example request to create a Flexi advance</ModalHeader>
      <br></br>
      <ModalCloseButton />
      <Box padding={'30px'}>
        <Box marginBottom={'15px'}>
          <Text>Enter the amount you would like to advance:</Text>
          <InputGroup>
            <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
              $
            </InputLeftElement>
            <Input w='150px' type='number' value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
          </InputGroup>
        </Box>
        <Divider margin={'15px 0 15px 0'} />


        <Box>
          <Heading as='h5' size='md'>Step 1</Heading>
          <Text>To create an advance, you must first gather advance offers. Perform a GET request to the following to gather account offers for a specific merchant.</Text> <Code w={'100%'}>{config.api.base}/flexi/api/v1/accounts/{props.account?.id}/advance-quote?advanceAmount={amount}</Code>
          {(merchant?.stage === MerchantStage.AdvanceOffersCreated || merchant?.stage === MerchantStage.PaymentsCreated) && !props.amountRequested && <Alert status='success'>
            <AlertIcon />
            Advance offers successfully created for {merchant.merchantId}.
          </Alert>}
          <Divider margin={'15px 0 15px 0'} />

        </Box>
      </Box>
      <Box padding={'0 30px 0 30px'}>
        <Heading as='h5' size='md'>Step 2</Heading>
        <Text>You then use the response from Step 1 and POST the payload to create an advance. <i>*note the change in the contracts field between response and subsequent request.</i><Code>{config.api.base}/flexi/api/v1/accounts/{props.account?.id}/advances</Code> </Text>
      </Box>
      <ModalBody>
        {(merchant?.stage === MerchantStage.PaymentsCreated) && <Alert status='success'>
          <AlertIcon />
          Application successfully created for {merchant.merchantId}.
        </Alert>}
        <Divider margin={'15px 0 15px 0'} />
        {!merchant && <Center><Spinner /></Center>}
        {merchant && <JsonViewer theme={colorMode as JsonViewerTheme} value={baseFlexiAdvance(amount, props.account)} />}
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}


export const AcceptOfferModal = (props: { acceptModalOpen: boolean, setAcceptModal: any, application?: Application, merchant?: Merchant, completedStep?: (merchant?: Merchant) => void }) => {
  const { colorMode } = useColorMode()
  const [time, setTime] = useState<number>(0);
  const [application, setApplication] = useState<Application>();
  const [merchant, setMerchant] = useState<Merchant>();

  const context = useContext(AppContext);

  const config = useConfig()

  useEffect(() => {
    if (!props.acceptModalOpen) {
      return;
    }
    if (!props.application) {
      console.log('in')
      let merchantLoop: any;

      const getMerchant = async () => {
        const merchantId = props.merchant?.merchantId;

        try {
          const response = await axios.get(`${config.api.base}${config.api.control.merchant}?merchantId=${merchantId}&partner=${context.userId}`)

          const mappedApplications = response.data.applications.map((x: any) => {
            return {
              merchantId: x.application.merchant_id,
              status: x.status,
              data: x
            }
          })

          setApplication(mappedApplications[0]);
          setMerchant(response.data.merchant);

        }
        catch (e) {
          console.log(e);
        }
      }

      if (props.merchant?.merchantId) {
        merchantLoop = setTimeout(() => {
          setTime(time + 1);
          getMerchant();
        }, 2000);
      }

      return () => {
        clearTimeout(merchantLoop);
      }
    }
    else {
      console.log('yes')
      setApplication(props.application);
    }

  }, [time, props.application])

  const onClose = () => {
    if (application?.data?.selectedOffer) {
      if (props.completedStep) {
        props.completedStep(merchant);
      }
      props.setAcceptModal(false)
    }
    else {
      props.setAcceptModal(false)
    }
  }

  return (<Modal
    isOpen={props.acceptModalOpen}
    onClose={onClose}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Accept an offer</ModalHeader>
      <Button cursor={'pointer'} size={'sm'} variant={'link'} position={'absolute'} right={'80px'} top={'25px'} onClick={() => window.open('https://liberis-api-v2.readme.io/reference/accepting-an-offer', '_blank')}>Accepting an offer <ExternalLinkIcon /></Button>

      <ModalCloseButton />
      <Box padding={'15px'}><Text>To accept an offer and create an application, make an API call to <Code>{config.api.base}/create/v2/offers/[offerId]/accept</Code> for the selected offer.</Text></Box>

      {props.merchant?.decision === Decision.Declined && <Alert status='error'>
        <AlertIcon />
        This merchant is set to be declined in the flow, it is not possible to accept any offers.
      </Alert>}

      <ModalBody>
        {application?.data?.selectedOffer && <Alert status='success'>
          <AlertIcon />
          Offer {application?.data?.selectedOffer.offer_id} has been successfully accepted!
        </Alert>}

        <SimpleGrid marginTop={'20px'} spacing={2} templateColumns='repeat(auto-fill, minmax(400px, 1fr))'>
          {!application?.data?.offers && <Center><Spinner /></Center>}
          {application?.data?.offers?.map((x: Offer, i: number) => {
            return (
              <Card key={x.offer_id} border={x.offer_id === application?.data?.selectedOffer?.offer_id ? '4px solid #C6F6D5' : ''}>
                <CardHeader marginBottom={'-30px'}>
                  <Heading size='md'>Offer {i + 1}</Heading>
                </CardHeader>
                <CardBody>
                  <div><Code padding={'15px'} children={<div>
                    <CopyButton text={`${config.api.base}/create/v2/offers/${x.offer_id}/accept`} />
                    <b>POST</b> {config.api.base}/create/v2/offers/{x.offer_id}/accept
                  </div>} />
                  </div>
                  <br />
                  <JsonViewer theme={colorMode as JsonViewerTheme} value={x} />

                </CardBody>
              </Card>)
          })

          }
        </SimpleGrid>
      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={onClose}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal >)
}

export const DealRepaidModal = (props: { dealRepaidModalOpen: boolean, setDealRepaidModal: any, deal?: Deal }) => {
  const config = useConfig()
  return (<Modal
    isOpen={props.dealRepaidModalOpen}
    onClose={() => props.setDealRepaidModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Close the deal</ModalHeader>
      <ModalCloseButton />
      <Box padding={'15px'}><Text>When a deal has been fully repaid and <Code>deal.balance = 0</Code>, a <Code>deal_complete</Code> webhook will be sent to your server. Depending on your payment method, you should action the removal of split / reroute the merchants settlement account to end the payment process and prevent overpayment. Once this has been actioned, we required you to reply to the webhook in similar fashion to Payment Setup, by following the <Code>actions.notify</Code> field on the hook once the payment process has been removed. Upon receiving this, we will close the deal.</Text></Box>

      <ModalBody>
        <SimpleGrid columns={1} spacing={5} margin={'10px'}>

          <Text>In order to confirm payment process removal for this deal, make a request to the following:</Text>
          {props.deal?.status === 'Repaid' && <Alert status='success'>
            <AlertIcon />
            Payment process removal completed, deal is now Repaid!
          </Alert>}

          {props.deal?.balance !== 0 && <Alert status='error'>
            <AlertIcon />
            Balance should be 0 before ending a split process and closing a deal.
          </Alert>}

          <Code padding={'20px'}>
            <CopyButton text={`${config.api.base}/actions/funding/dc-notify/${props.deal?.id}`} />
            <b>POST</b> {config.api.base}/actions/funding/dc-notify/{props.deal?.id}
          </Code>

        </SimpleGrid>

      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setDealRepaidModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const AccountRepaidModal = (props: { dealRepaidModalOpen: boolean, setDealRepaidModal: any, account?: FlexiAccount }) => {
  const config = useConfig()
  return (<Modal
    isOpen={props.dealRepaidModalOpen}
    onClose={() => props.setDealRepaidModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Close the deal</ModalHeader>
      <ModalCloseButton />
      <Box padding={'15px'}><Text>When a deal has been fully repaid and <Code>deal.balance = 0</Code>, a <Code>deal_complete</Code> webhook will be sent to your server. Depending on your payment method, you should action the removal of split / reroute the merchants settlement account to end the payment process and prevent overpayment. Once this has been actioned, we required you to reply to the webhook in similar fashion to Payment Setup, by following the <Code>actions.notify</Code> field on the hook once the payment process has been removed. Upon receiving this, we will close the deal.</Text></Box>

      <ModalBody>
        <SimpleGrid columns={1} spacing={5} margin={'10px'}>

          <Text>In order to confirm payment process removal for this deal, make a request to the following:</Text>
          {props.account?.status === 'Repaid' && <Alert status='success'>
            <AlertIcon />
            Payment process removal completed, deal is now Repaid!
          </Alert>}

          {props.account?.outstandingBalance !== 0 && <Alert status='error'>
            <AlertIcon />
            Balance should be 0 before ending a split process and closing a deal.
          </Alert>}

          <Code padding={'20px'}>
            <CopyButton text={`${config.api.base}/actions/funding/dc-notify/${props.account?.id}`} />
            <b>POST</b> {config.api.base}/actions/funding/dc-notify/{props.account?.id}
          </Code>

        </SimpleGrid>

      </ModalBody>
      <ModalFooter>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setDealRepaidModal(false)}>
          Close
        </Button>

      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const TestWebhookModal = (props: { testWebhookModal: boolean, setTestWebhookModal: any, userId?: string, selectedMerchant?: Merchant, connectionLost: any, canRenew?: boolean }) => {
  const [merchant, setMerchant] = useState<Merchant>();
  const config = useConfig()
  const [testWebhook, setTestWebhook] = useState<string>();
  const [time, setTime] = useState(0);

  const toast = useToast();

  const sendTestHook = async () => {
    try {
      await axios.post(`${config.api.base}${config.api.control.webhooks}`, { merchantId: merchant?.merchantId, webhook: testWebhook, partner: props.userId })

      toast({
        title: 'Webhook test created',
        description: "The result of the test will be shown above.",
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    }
    catch (e) {
      props.connectionLost();
    }
  }

  const [merchants, setMerchants] = useState<Merchant[]>();

  useEffect(() => {
    if (!props.testWebhookModal) {
      return;
    }
    const getMerchants = async () => {
      try {
        const response = await axios.get(`${config.api.base}${config.api.control.merchants}?partner=${props.userId}`)
        setMerchants(response.data);
      }
      catch (e) {
        props.connectionLost();
      }
    }
    if (!merchant && props.selectedMerchant) {
      setMerchant(props.selectedMerchant)
    }

    getMerchants();

  }, [time])



  return (
    <Modal
      isOpen={props.testWebhookModal}
      onClose={() => props.setTestWebhookModal(false)}
      motionPreset='slideInBottom'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send a test webhook</ModalHeader>
        <Box padding={'15px'}><Text>This will send a test webhook from Liberis to your server. This does not affect the state of your test merchants, only one message will be sent per test.</Text></Box>
        <ModalCloseButton />
        <ModalBody>

          <FormControl isRequired>
            <FormLabel>Select merchant</FormLabel>
            <Select value={merchant?.merchantId} onChange={(e) => { setMerchant(merchants?.filter((x: Merchant) => x.merchantId === e.target.value)[0]) }} placeholder='Select merchant'>
              {merchants?.map((x: Merchant) => {
                return <option>{x.merchantId}</option>
              })}
            </Select>
          </FormControl>

          {merchant?.stage !== MerchantStage.DealCreated &&
            <Alert borderRadius={'5px'} marginTop={'10px'} status='error'>
              <AlertIcon />
              <Text>Merchant must have a deal created to test webhooks</Text>
            </Alert>
          }

          {!merchant?.webhookUrl &&
            <Alert borderRadius={'5px'} marginTop={'10px'} status='error'>
              <AlertIcon />
              <Text>Merchant must have a webhook url configured</Text>
            </Alert>
          }


          <FormControl margin={'20px 0 20px 0'}>
            <FormLabel>Destination Url</FormLabel>
            <Text><Code><b>POST</b> {merchant?.webhookUrl}</Code></Text>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Select webhook</FormLabel>
            <Select onChange={(e) => { setTestWebhook(e.target.value) }} placeholder='Select webhook'>
              <option disabled={merchant?.stage !== MerchantStage.DealCreated}>awaiting_split_validation</option>
              <option disabled={merchant?.stage !== MerchantStage.DealCreated}>settlement_account_ready</option>
              <option disabled={merchant?.stage !== MerchantStage.DealCreated}>deal_started</option>
              <option disabled={merchant?.stage !== MerchantStage.DealCreated}>deal_complete</option>
              <option disabled={merchant?.stage !== MerchantStage.DealCreated}>deal_renewed</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {merchant?.merchantId && merchant.stage === MerchantStage.DealCreated && merchant.webhookUrl && <Button mr={3} onClick={() => { sendTestHook(); props.setTestWebhookModal(false) }} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'}>Send hook</Button>}
          <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setTestWebhookModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal >
  )
}


export const ContractModal = (props: { contractModal: boolean, setContractModal: any, applicationId?: string, currentContractStep?: string }) => {

  const [messages, setMessages] = useState<any[]>([]);
  const [frameHeight, setFrameHeight] = useState<number>(0)
  const [filterHeights, setFilterHeights] = useState<boolean>(false);

  useEffect(() => {

    window.addEventListener(
      "message",
      function (event) {
        if (typeof event.data == 'string') {
          setMessages([{ data: event.data, date: new Date() }, ...messages])
          try {
            const message = JSON.parse(event.data);
            if (message.containerHeight && message.containerHeight !== frameHeight) {
              console.log(message.containerHeight);
              setFrameHeight(message.containerHeight)
            }
          }
          catch (e) {
            console.log(e);
          }
        }
      },
      false,
    );
  }, [messages])

  return (
    <Modal
      isOpen={props.contractModal}
      onClose={() => props.setContractModal(false)}
      motionPreset='slideInBottom'
      size='2xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contracting signing with Click2Sign</ModalHeader>
        <ModalCloseButton />

        {props.currentContractStep === 'Download' &&

          <Box p={5} w='100%'><Alert status='success' >
            <AlertIcon />
            Contract signing has been completed and the merchant moved to payment setup.
          </Alert>
          </Box>}

        <ModalBody padding={0}>
          {messages.length > 0 &&
            <Box w='20rem' maxH='90%' pos={'fixed'} top='4rem' right={'5rem'}>
              <Stack spacing={4}>
                <Card>
                  <CardHeader>
                    <Heading size='md'>Postmessages received</Heading>
                    <Button variant='link' color='grey' w='150px' fontSize={'12px'} onClick={() => window.open(`${'https://liberis-api-v2.readme.io/reference/using-the-click2sign-iframe#postmessages'}`, '_blank')}>PostMessages reference<ExternalLinkIcon /></Button>
                    <Checkbox
                      isChecked={filterHeights}
                      onChange={(e) => setFilterHeights(!filterHeights)}
                    >
                      Filter height changes
                    </Checkbox>
                  </CardHeader>
                </Card>


                {messages.filter(x => {
                  if (filterHeights) {
                    return !x.data.includes('containerHeight')
                  }
                  return true;
                }).map((message: any, index: number) => {
                  return <Card p='5'>
                    {message.date.getTime() > new Date().getTime() - 5000 ? <Tag colorScheme="green">New</Tag> : ''}
                    <Text>
                      <FormLabel>Message</FormLabel>
                      <Code w='100%'>{message.data}</Code>
                    </Text>
                    <Text>
                      <Code w='100%'>{message.date.toISOString()}</Code>
                    </Text>
                  </Card>
                })}
              </Stack>
            </Box>
          }
          <Box w='100%'>
            {frameHeight === 0 && <Center><Spinner marginTop={'50px'}
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='#E7FF7C'
              size='xl'
            />
            </Center>}
            <iframe scrolling="no" height={frameHeight} width={'100%'} src={`${window.location.origin}/#/contract?applicationId=${props.applicationId}`}></iframe>
          </Box>

        </ModalBody>
        <ModalFooter>
          <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setContractModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const ViewWebhook = (props: { webhookModal: boolean, setWebhookModal: any, selectedWebhook?: Webhook }) => {
  const { colorMode } = useColorMode()
  return (
    <Modal
      isOpen={props.webhookModal}
      onClose={() => props.setWebhookModal(false)}
      motionPreset='slideInBottom'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>View webhook data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <JsonViewer theme={colorMode as JsonViewerTheme} value={props.selectedWebhook} />

        </ModalBody>
        <ModalFooter>
          <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setWebhookModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const ApplicationDataWebhook = (props: { dataModalOpen: boolean, setDataModal: any, selectedApplication?: Application }) => {
  const { colorMode } = useColorMode()
  return (
    <Modal
      isOpen={props.dataModalOpen}
      onClose={() => props.setDataModal(false)}
      motionPreset='slideInBottom'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Application data</ModalHeader>
        <ModalCloseButton />
        <Box padding={'15px'}><Text>The below data comprises of a larger application state than is accessible from the Liberis Create API. This can be used to track application requests, offers and other metadata.</Text></Box>

        <ModalBody>
          <JsonViewer theme={colorMode as JsonViewerTheme} value={props.selectedApplication?.data} />
        </ModalBody>
        <ModalFooter>
          <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setDataModal(false)}>
            Close
          </Button>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const FlexiApplicationDataWebhook = (props: { dataModalOpen: boolean, setDataModal: any, selectedApplication?: FlexiApplication }) => {
  const { colorMode } = useColorMode()
  return (
    <Modal
      isOpen={props.dataModalOpen}
      onClose={() => props.setDataModal(false)}
      motionPreset='slideInBottom'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Application data</ModalHeader>
        <ModalCloseButton />
        <Box padding={'15px'}><Text>The below data is what you have submitted via the POST /flexi/api/v1/application endpoint with added Liberis specific fields. This can be used to track application requests, offers and other metadata.</Text></Box>

        <ModalBody>
          <JsonViewer theme={colorMode as JsonViewerTheme} value={props.selectedApplication} />
        </ModalBody>
        <ModalFooter>
          <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setDataModal(false)}>
            Close
          </Button>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const FlexiAdvanceDataWebhook = (props: { dataModalOpen: boolean, setDataModal: any, selectedAdvance?: FlexiAdvance }) => {
  const { colorMode } = useColorMode()
  return (
    <Modal
      isOpen={props.dataModalOpen}
      onClose={() => props.setDataModal(false)}
      motionPreset='slideInBottom'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Advance data</ModalHeader>
        <ModalCloseButton />
        <Box padding={'15px'}><Text>The below data is what you have submitted via the POST advances endpoint with added Liberis specific fields. This can be used to track advance requests, offers and other metadata.</Text></Box>

        <ModalBody>
          <JsonViewer theme={colorMode as JsonViewerTheme} value={props.selectedAdvance} />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => props.setDataModal(false)} fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'}>
            Close
          </Button>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const ApplicationStatusWebhook = (props: { statusModalOpen: boolean, setStatusModal: any, selectedApplication?: Application, connectionLost: any }) => {
  const config = useConfig()
  const [status, setStatus] = useState<string>()

  const changeStatus = async () => {
    try {
      await axios.patch(`${config.api.base}${config.api.control.application}/status`, { application_id: props.selectedApplication?.data.application_id, status })
    } catch (e) {
      props.connectionLost();
    }
    return;
  }
  return (<Modal
    isOpen={props.statusModalOpen}
    onClose={() => props.setStatusModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Change application status for {props.selectedApplication?.data?.application?.merchant_id}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl mt={4} isRequired>
          <FormLabel>Status</FormLabel>
          <Select onChange={(e) => setStatus(e.target.value)} placeholder='Select status'>
            <option>Created</option>
            <option>Assigned</option>
            <option>Quoted</option>
            <option>Submitted</option>
            <option>Evidenced</option>
            <option>Approved</option>
            <option>Agreement Signed</option>
            <option>Split Placed</option>
            <option>Payments Set</option>
            <option>Processed by Liberis</option>
            <option>Funded</option>
            <option>Declined</option>
            <option>Withdrawn</option>
            <option>Rescinded</option>
            <option>Written Off</option>
            <option>Cancelled</option>
            <option>Lost</option>
            <option>Ineligible</option>
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={() => { changeStatus(); props.setStatusModal(false) }} fontFamily={'FoundryPlek'} marginTop={'0px'} padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} bg='#E7FF7C'>SAVE</Button>
        <Button fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setStatusModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const FlexiApplicationStatusWebhook = (props: { statusModalOpen: boolean, setStatusModal: any, selectedApplication?: FlexiApplication, connectionLost: any }) => {
  const config = useConfig()
  const [status, setStatus] = useState<string>()

  const changeStatus = async () => {
    try {
      await axios.patch(`${config.api.base}/control/flexi-application/status`, { id: props.selectedApplication?.id, status })
    } catch (e) {
      props.connectionLost();
    }
    return;
  }
  return (<Modal
    isOpen={props.statusModalOpen}
    onClose={() => props.setStatusModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Change Flexi application status for {props.selectedApplication?.merchantId}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl mt={4} isRequired>
          <FormLabel>Status</FormLabel>
          <Select onChange={(e) => setStatus(e.target.value)} placeholder='Select status'>
            <option>Submitted</option>
            <option>Approved</option>
            <option>Activated</option>
            <option>Cancelled</option>
            <option>Declined</option>
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={() => { changeStatus(); props.setStatusModal(false) }} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'}>SAVE</Button>
        <Button mr={3} fontFamily={'FoundryPlek'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} onClick={() => props.setStatusModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const FlexiAdvanceStatusWebhook = (props: { statusModalOpen: boolean, setStatusModal: any, selectedAdvance?: FlexiAdvance, connectionLost: any }) => {
  const config = useConfig()
  const [status, setStatus] = useState<string>()

  const changeStatus = async () => {
    try {
      await axios.patch(`${config.api.base}/control/advance/status`, { id: props.selectedAdvance?.id, status })
    } catch (e) {
      props.connectionLost();
    }
    return;
  }
  return (<Modal
    isOpen={props.statusModalOpen}
    onClose={() => props.setStatusModal(false)}
    motionPreset='slideInBottom'
    size='xl'
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Change Flexi application status for {props.selectedAdvance?.merchantId}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl mt={4} isRequired>
          <FormLabel>Status</FormLabel>
          <Select onChange={(e) => setStatus(e.target.value)} placeholder='Select status'>
            <option>ReadyForFunding</option>
            <option>NeedsIntervention</option>
            <option>Rejected</option>
            <option>Funded</option>
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={() => { changeStatus(); props.setStatusModal(false) }} colorScheme="green">SAVE</Button>
        <Button fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} w={'100px'} mr={3} onClick={() => props.setStatusModal(false)}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>)
}

export const CustomInput = (props: { type: string, placeholder: string, label: string, setField: any, field: any }) => {
  const { colorMode } = useColorMode();
  const lightMode = colorMode === 'light';
  return (
    <Box p='10px 10px 5px 10px' border='1px solid #D2D6DB' borderRadius={'8px'}>
      <Text color={lightMode ? '#6C737F' : '#dfdfdf'} fontSize={'11px'} fontWeight={'500'} lineHeight={'12px'}>{props.label}</Text>
      <Input color={lightMode ? '#6C737F' : '#dfdfdf'} value={props.field} type={props.type} variant={'unstyled'} h='30px' p='0' border={'none'} onChange={(e) => props.setField(e.target.value)} placeholder={props.placeholder}></Input>
    </Box>
  )
}

export const CustomDropdown = (props: { placeholder: string, label: string, setField: any, field: any, options: string[] }) => {
  const { colorMode } = useColorMode();
  const lightMode = colorMode === 'light';
  return (
    <Box p='10px 10px 5px 10px' border='1px solid #D2D6DB' borderRadius={'8px'}>
      <Text color={lightMode ? '#6C737F' : '#dfdfdf'} fontSize={'11px'} fontWeight={'500'} lineHeight={'12px'}>{props.label}</Text>
      <Select color={lightMode ? '#6C737F' : '#dfdfdf'} value={props.field} variant={'unstyled'} m='0' h='30px' padding={'0px !important'} border={'none'} onChange={(e) => props.setField(e.target.value)} placeholder={props.placeholder}>
        {props.options.map(x => {
          return <option>{x}</option>
        })}
      </Select>
    </Box >
  )
}

export const CreateMerchantModal = (props: { modalOpen: boolean, setModal: any, userId?: string, completedStep?: (merchant: Merchant) => void }) => {

  const [merchantId, setMerchantId] = useState<string>();
  const [product, setProduct] = useState<string>('BCA');
  const [currency, setCurrency] = useState<string>('GBP');
  const [decision, setDecision] = useState<string>('Approved');
  const [contractEnabled, setContractEnabled] = useState<boolean>(true);
  const [nearestPricing, setNearestPricing] = useState<boolean>(false);
  const [repaymentMech, setRepaymentMech] = useState<string>('Partner Split');
  const [minimum, setMinimum] = useState<string>("2500");
  const [maximum, setMaximum] = useState<string>("50000");
  const [webhookUrl, setUrl] = useState<string>();
  const [webhookType, setWebhookType] = useState<string>('No Auth');
  const [webhookSecret, setWebhookSecret] = useState<string>();
  const [webhookClient, setWebhookClient] = useState<string>();
  const [webhookHMAC, setWebhookHMAC] = useState<string>();
  const [webhookTokenPayload, setWebhookTokenPayload] = useState<string>();
  const [webhookTokenEndpoint, setWebhookTokenEndpoint] = useState<string>();
  const config = useConfig()

  const navigate = useNavigate();
  const createMerchant = async () => {
    if (merchantId && currency && decision && props.userId) {

      const merchantIdTrimmed = merchantId.replace(/ /g, '');
      const merchant: Merchant = {
        merchantId: merchantIdTrimmed,
        product: ProductType[product as keyof typeof ProductType],
        currency,
        limits: {
          minimum: Number(minimum),
          maximum: Number(maximum)
        },
        webhookUrl,
        webhookType,
        webhookTokenPayload,
        webhookTokenEndpoint,
        webhookClient,
        webhookSecret,
        webhookHMAC,
        decision: Decision[decision as keyof typeof Decision],
        partner: props.userId,
        paymentMechanism: repaymentMech,
        nearestPricing,
        contractEnabled
      }

      try {
        await axios.post(`${config.api.base}${config.api.control.merchants}`, merchant)
        props.setModal(false);
        if (props.completedStep) {
          props.completedStep(merchant)
        }
        navigate(`/${product === ProductType.Flexi ? 'flexi-merchant' : 'merchant'}?merchantId=${merchantIdTrimmed}`);

      } catch (e) {
        console.log(e);
      }
    }

    return;
  }

  return (
    <Modal
      isOpen={props.modalOpen}
      onClose={() => props.setModal(false)}
      motionPreset='slideInBottom'

    >
      <ModalOverlay />
      <ModalContent>

        <ModalHeader borderBottom={'1px solid #1119271F'}>
          <Image w='42px' h='42px' src={storefront} marginBottom={'10px'}></Image>
          <Text>Create a merchant</Text>
          <Text fontSize={'14px'} color={'#6C737F'}>
            This will be the basis for your testing.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody >
          <Stack spacing={2} marginTop={'15px'}>
            <FormControl isRequired>
              <CustomInput type="text" label="Merchant ID*" placeholder="Merchant ID" setField={setMerchantId} field={merchantId} />
            </FormControl>

            <FormControl mt={4} isRequired>
              <CustomDropdown placeholder="Select product" label="Product" setField={setProduct} field={product} options={[ProductType.BCA, ProductType.Flexi]}></CustomDropdown>
            </FormControl>

            <FormControl mt={4} isRequired>
              <CustomDropdown placeholder="Select currency" label="Currency*" setField={setCurrency} field={currency} options={['USD', 'GBP', 'EUR', 'CAD', 'ISK', 'SEK', 'CZK', 'PLN', 'DKK']}></CustomDropdown>
            </FormControl>

            <FormControl mt={4} isRequired>
              <CustomDropdown placeholder="Select decision" label="Decision result*" setField={setDecision} field={decision} options={[Decision.Approved, Decision.Declined, Decision.Referred]}></CustomDropdown>
            </FormControl>

            <FormControl mt={4} isRequired>
              <CustomDropdown placeholder="Select payment mechanism" label="Payment mechanism*" setField={setRepaymentMech} field={repaymentMech} options={[MerchantRepayment.PartnerSplit, MerchantRepayment.LiberisSplit, MerchantRepayment.HybridSplit]}></CustomDropdown>
            </FormControl>

            <FormControl mt={4} isRequired>
              <CustomInput type="number" label={product === ProductType.BCA ? "Minimum offer*" : "Minimum amount"} placeholder="2500" setField={setMinimum} field={minimum} />
            </FormControl>

            <FormControl mt={4} isRequired>
              <CustomInput type="number" label={product === ProductType.BCA ? "Maximum offer*" : "Maximum amount"} placeholder="50000" setField={setMaximum} field={maximum} />
            </FormControl>


            <FormControl mt={4} isRequired>
              <Checkbox
                isChecked={nearestPricing}
                onChange={(e) => setNearestPricing(!nearestPricing)}
              >
                Enable nearest pricing
              </Checkbox>
            </FormControl>

            <FormControl mt={4} isRequired>
              <Checkbox
                isChecked={contractEnabled}
                onChange={(e) => setContractEnabled(!contractEnabled)}
              >
                Enable embedded contract
              </Checkbox>
            </FormControl>

            <Divider margin={'10px 0 10px 0'} />

            <FormControl mt={4}>
              <FormLabel>Webhook configuration<Button position={'absolute'} right={'0'} top={0} variant='link' color='grey' w='150px' fontSize={'12px'} onClick={() => window.open(`${'https://public.requestbin.com/r'}`, '_blank')}>Create a request bin <ExternalLinkIcon /></Button></FormLabel>
              <CustomDropdown placeholder="Select auth type" label="Authentication type" setField={setWebhookType} field={webhookType} options={[WebhookType.NoAuth, WebhookType.Basic, WebhookType.Oauth2, WebhookType.Oauth2ClientCreds]}></CustomDropdown>
              <FormControl mt={4}>
                <CustomInput type="text" label="Webhook Url" placeholder='https://example.website.com' setField={setUrl} field={webhookUrl} />
              </FormControl>

              <FormControl mt={4} >
                <CustomInput type="text" label="HMAC secret (optional)" placeholder="HMAC secret" setField={setWebhookHMAC} field={webhookHMAC} />
              </FormControl>

              {webhookType === WebhookType.Oauth2 || webhookType === WebhookType.Oauth2ClientCreds &&
                <Box>
                  <FormControl mt={4} >
                    <CustomInput type="text" label="Token endpoint" placeholder="https://example.website.com" setField={setWebhookTokenEndpoint} field={webhookTokenEndpoint} />
                  </FormControl>
                  <FormControl mt={4} >
                    <CustomInput type="text" label="client_id" placeholder="Client Id" setField={setWebhookClient} field={webhookClient} />
                  </FormControl>
                  <FormControl mt={4} >
                    <CustomInput type="text" label="client_secret" placeholder="Client Secret" setField={setWebhookSecret} field={webhookSecret} />
                  </FormControl>
                </Box>
              }
              {webhookType === WebhookType.Basic &&
                <Box>
                  <FormControl mt={4} >
                    <CustomInput type="text" label="API Key" placeholder="API Key" setField={setWebhookSecret} field={webhookSecret} />
                  </FormControl>
                </Box>
              }
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {merchantId &&
            currency &&
            decision &&
            minimum &&
            maximum &&
            repaymentMech &&
            <Button mr={3} onClick={() => createMerchant()} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} >Create merchant</Button>}

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


export const CopyButton = (props: { text: string }) => {
  const toast = useToast();
  return <Button variant='link' padding={'0px'} margin="10px" onClick={() => {
    copy(props.text);
    toast({
      title: 'Copied to clipboard',
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }}><CopyIcon /></Button>
}


export const Footer = () => {
  const { colorMode } = useColorMode();
  return <Box marginTop={'10%'} bg={colorMode === 'light' ? '#F8F9FA' : '#2d3748'} h='300px' p={20}>
    <Image w={'300px'} src={logo}></Image>
    <Divider m={'20px 0 20px 0'} colorScheme={'red'} />
    <Text fontSize={'12px'}>All rights reserved.</Text>
  </Box>
}

export default App;
